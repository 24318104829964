import React, { useEffect, useState, useCallback, useRef } from "react";
import CustomSidebar from "../CustomSidebar.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faEdit, faPlusSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import axios from "axios";
import { useAuth } from "../../../AuthContext.tsx";
import toast, { Toaster } from "react-hot-toast";

library.add(faBars, faEdit, faPlusSquare, faTrash);

export default function Storico() {
  const { idToken, selectedShop } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [betterOpening, setBetterOpening] = useState<any[]>([]); // Array di array di oggetti
  const [years, setYears] = useState<number[]>([]);
  const [selectedOpening, setSelectedOpening] = useState<any | null>(null);
  const [selectedGain, setSelectedGain] = useState<any | null>(null);
  const [employee, setEmployee] = useState<any[]>([]);
  const [turnPopup, setTurnPopup] = useState<boolean>(false);
  const tmpSelectedShop = useRef<any>(null);

  const toggledTurnPopup = (opening) => () => {
    setSelectedOpening(opening);
    setTurnPopup(true);
  };

  const toggledGainPopup = (opening) => () => {
    setSelectedGain(opening);
  };

  const toggledDayPopup = (opening) => () => {
    setSelectedOpening(opening);
  };

  const betterDate = (date: string) => {
    console.log("Date:", date);
    // Presa la stringa gg/mm/aaaa, la converte in Sabato 1 Gennaio 2022
    const [day, month, year] = date.split("/");
    const newDate = new Date(Number(year), Number(month) - 1, Number(day));
    return newDate.toLocaleDateString("it-IT", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleAddTurn = async (opening) => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addTurn",
        {
          Rif_data: opening.data.split("/").reverse().join("-"),
          pagato: false,
          CF: opening.CF,
          Rif_Indirizzo: selectedShop?.Indirizzo,
          data: opening.data.split("/").reverse().join("-"),
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          getOpeningDb(new Date().getFullYear());
          setSelectedOpening(null);
          setTurnPopup(false);
          toast.success("Apertura aggiunta con successo");
        } else {
          toast.error("Errore nell'aggiunta dell'apertura");
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTurn = async (opening) => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/deleteTurn",
        {
          Rif_data: opening.data.split("/").reverse().join("-"),
          CF: opening.CF,
          Rif_Indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          getOpeningDb(opening.Rif_data.split("/").reverse().join("-").split("-")[0]);
          setSelectedOpening(null);
          setTurnPopup(false);
          toast.success("Apertura eliminata con successo");
        } else {
          toast.error("Errore nell'eliminazione dell'apertura");
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateTurn = async (opening, pagato) => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/updateTurn",
        {
          Rif_data: opening.data.split("/").reverse().join("-"),
          pagato: pagato,
          CF: opening.CF,
          Rif_Indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Apertura aggiornata con successo");
          getOpeningDb(opening.Rif_data.split("/").reverse().join("-").split("-")[0]);
          setSelectedOpening(null);
        } else {
          toast.error("Errore nell'aggiornamento dell'apertura");
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployee = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getEmployee", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          const formattedEmployee = response.data.result.map((employee) => ({
            id: employee.CF,
            Nome: employee.Nome,
            Cognome: employee.Cognome,
            CF: employee.CF,
          }));
          setEmployee(formattedEmployee);
        }
      })
      .catch((error) => {
        console.error("Errore nel recupero dei commessi:", error);
      });
  }, [idToken]);

  const getOpeningDb = useCallback(
    async (anno) => {
      try {
        await axios
          .post(
            "https://tommasoceredi.it/api/v1/getAllOpening",
            {
              indirizzo: selectedShop?.Indirizzo,
              anno: anno,
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.result.length === 0) {
              return;
            }
            const itDate = response.data.result.map((opening) => {
              // ogni apertura il campo data viene convertito in toLocaleDateString("it-IT")
              const date = new Date(opening.data);
              const formattedDate = date.toLocaleDateString("it-IT");
              return {
                id: `${anno}-${formattedDate}`,
                anno,
                data: formattedDate,
                oraInizio: opening.oraInizio,
                oraFine: opening.oraFine,
                oraInizioP: opening.oraInizioP,
                oraFineP: opening.oraFineP,
                incasso: opening.incasso,
                incassoB: opening.incassoB,
                indirizzo: opening.Indirizzo,
              };
            });
            const sortedData = itDate.sort((a, b) => {
              const dateA = new Date(a.data.split("/").reverse().join("-"));
              const dateB = new Date(b.data.split("/").reverse().join("-"));
              return dateB.getTime() - dateA.getTime(); // Inverti il confronto per ordinare dal più recente
            });
            const organizedData = sortedData.reduce((acc, opening) => {
              const anno = new Date(opening.data.split("/").reverse().join("-")).getFullYear();
              if (!acc[anno]) {
                acc[anno] = [];
              }
              acc[anno].push(opening);
              return acc;
            }, {});
            axios
              .post(
                "https://tommasoceredi.it/api/v1/getTurn",
                {
                  Rif_Indirizzo: selectedShop?.Indirizzo,
                  anno: anno,
                },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )
              .then((response) => {
                const itDate = response.data.result.map((turno) => {
                  // ogni apertura il campo data viene convertito in toLocaleDateString("it-IT")
                  const dipendente = employee.find((emp) => emp.CF === turno.CF);
                  return {
                    id: `${turno.Rif_Indirizzo}-${turno.Rif_data}`,
                    data: new Date(turno.data).toLocaleDateString("it-IT"),
                    pagato: turno.pagato,
                    CF: turno.CF,
                    Rif_Indirizzo: turno.Rif_Indirizzo,
                    Rif_data: new Date(turno.Rif_data).toLocaleDateString("it-IT"),
                    Nome: dipendente ? dipendente.Nome : "Sconosciuto",
                    Cognome: dipendente ? dipendente.Cognome : "Sconosciuto",
                  };
                });
                // Cerco in openingDb l'apertura con la stessa data e indirizzo. Se la trovo aggiungo una lista di turni (pagato e CF)
                const betterOpening = Object.keys(organizedData).map((anno) => {
                  return organizedData[anno].map((opening) => {
                    const turni = itDate.filter((turno) => {
                      const openingDate = opening.data.trim();
                      const turnoDate = turno.Rif_data.trim();
                      return openingDate === turnoDate;
                    });
                    return { ...opening, turni };
                  });
                });
                setBetterOpening((prev) => ({
                  ...prev,
                  [anno]: betterOpening[0],
                }));
                setYears([...years, anno]);
              });
          });
      } catch (error) {
        console.error("Errore nel recupero delle aperture:", error);
      }
    },
    [selectedShop, idToken, employee, years]
  );

  const hadleUpdateGain = async (opening) => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/updateGainOpening",
        {
          Indirizzo: selectedShop?.Indirizzo,
          data: opening.data.split("/").reverse().join("-"),
          incasso: opening.incasso,
          incassoB: opening.incassoB,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Indirizzo:", selectedShop?.Indirizzo, "Data:", opening.data.split("/").reverse().join("-"));
        console.log("Incasso:", opening.incasso, "Incasso B:", opening.incassoB);
        if (response.data.status) {
          getOpeningDb(opening.anno);
          setSelectedGain(null);
          setSelectedOpening(null);
          toast.success("Incasso aggiornato con successo");
        } else {
          toast.error("Errore nell'aggiornamento dell'incasso");
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedShop && years.length === 0) {
        await getEmployee();
        await getOpeningDb(new Date().getFullYear());
      }
    }
    if (tmpSelectedShop.current !== selectedShop) {
      setBetterOpening([]);
      setYears([]);
      tmpSelectedShop.current = selectedShop;
      console.log("Selected shop changed: ", selectedShop, " - ", tmpSelectedShop.current);
    }
    fetchData();
  }, [selectedShop, getOpeningDb, betterOpening, years, getEmployee]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
          custom: {
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className="min-h-screen flex flex-col  " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="max-w-screen-xl w-full mx-auto p-4">
              <h1 className="text-white text-2xl font-bold p-4">Storico Aperture</h1>
              <div className="overflow-x-auto">
                {Object.keys(betterOpening)
                  .sort((a, b) => Number(b) - Number(a)) // Ordina gli anni in ordine decrescente
                  .map((anno) => (
                    <div key={anno}>
                      <h2 className="text-white text-2xl font-bold p-4">{anno}</h2>
                      <table className="min-w-full bg-white mb-8">
                        <thead className="bg-gray-800 text-white">
                          <tr>
                            <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm ">Data</th>
                            <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Incasso</th>
                            <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Commessi</th>
                            <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm ">Tipo</th>
                            <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Azione</th>
                          </tr>
                        </thead>
                        <tbody className="text-black">
                          {betterOpening[anno].map((opening) => (
                            <tr key={opening.id}>
                              <td className="text-center align-middle text-left py-3 px-4">{betterDate(opening.data)}</td>
                              <td className="text-center align-middle text-right py-3 px-4">
                                {opening.incasso + opening.incassoB}€
                              </td>
                              <td className="text-center align-middle text-left py-3 px-4">
                                {opening.turni?.length > 0 ? opening.turni?.length : "Nessun commesso"}
                              </td>
                              <td className="text-center align-middle text-left py-3 px-4">
                                {opening.oraFine !== "" ? "INTERO" : "POMERIGGIO"}
                              </td>
                              <td className="text-center align-middle text-left py-3 px-4 items-center flex justify-center">
                                <FontAwesomeIcon icon={faEdit} className="cursor-pointer" onClick={toggledDayPopup(opening)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
              </div>
              <button
                className="bg-gray-800 text-white p-2 rounded mt-4"
                onClick={() => {
                  const lastYear = years.length > 0 ? Math.min(...years) - 1 : new Date().getFullYear() - 1;
                  getOpeningDb(lastYear);
                }}
              >
                Carica anno precedente
              </button>

              {selectedOpening && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center">
                  <div className="bg-white p-4 rounded">
                    <h2 className="text-2xl font-bold">Apertura del {selectedOpening.data}</h2>
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <table className="min-w-full bg-white mt-4">
                          <thead className="bg-slate-700 text-white">
                            <tr>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Tipo</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ora Inizio</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ora Fine</th>
                            </tr>
                          </thead>
                          <tbody className="text-black">
                            <tr>
                              <td className="text-left py-3 px-4">MATTINA</td>
                              <td className="text-left py-3 px-4">
                                {selectedOpening.oraInizio === "" ? "Chiuso" : selectedOpening.oraInizio}
                              </td>
                              <td className="text-left py-3 px-4">
                                {selectedOpening.oraFine === "" ? "Chiuso" : selectedOpening.oraFine}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left py-3 px-4">POMERIGGIO</td>
                              <td className="text-left py-3 px-4">
                                {selectedOpening.oraInizioP === "" ? "Chiuso" : selectedOpening.oraInizioP}
                              </td>
                              <td className="text-left py-3 px-4">
                                {selectedOpening.oraFineP === "" ? "Chiuso" : selectedOpening.oraFineP}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-between">
                        <table className="min-w-full bg-white mt-4">
                          <thead className="bg-slate-700 text-white">
                            <tr>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Incasso</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Incasso B</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm"></th>
                            </tr>
                          </thead>
                          <tbody className="text-black">
                            <tr>
                              <td className="text-left py-3 px-4">{selectedOpening.incasso}€</td>
                              <td className="text-left py-3 px-4">{selectedOpening.incassoB}€</td>
                              <td className="text-left py-4 px-4 items-end justify-end flex">
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="cursor-pointer"
                                  onClick={toggledGainPopup(selectedOpening)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-between">
                        <table className="min-w-full bg-white mt-4">
                          <thead className="bg-slate-700 text-white">
                            <tr>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">NOME</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">PAGATO</th>
                              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                                <FontAwesomeIcon
                                  icon={faPlusSquare}
                                  className="cursor-pointer text-slate-900 text-xl"
                                  onClick={toggledTurnPopup(selectedOpening)}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-black">
                            {selectedOpening.turni?.map((turno) => (
                              <tr key={turno.CF}>
                                <td className="text-left py-3 px-4">
                                  {employee.find((emp) => emp.CF === turno.CF)?.Nome}{" "}
                                  {employee.find((emp) => emp.CF === turno.CF)?.Cognome}
                                </td>
                                <td className="text-left py-3 px-4">
                                  <label className="inline-flex items-center me-5 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      value=""
                                      className="sr-only peer"
                                      checked={turno.pagato === "1"}
                                      onChange={() => {
                                        handleUpdateTurn(turno, turno.pagato === "1" ? 0 : 1);
                                      }}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                  </label>
                                </td>
                                <td className="text-left py-4 px-4 items-end justify-end flex">
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleDeleteTurn(turno);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <button className="bg-gray-800 text-white p-2 rounded mt-4" onClick={() => setSelectedOpening(null)}>
                        Chiudi
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {selectedGain && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center">
                  <div className="bg-white p-4 rounded">
                    <h2 className="text-2xl font-bold">Aggiorna incasso del {selectedGain.data}</h2>
                    <div className="flex flex-col">
                      <p className="text-lg font-bold mt-2">Incasso</p>
                      <input
                        type="number"
                        className="border border-gray-300 p-2 rounded"
                        value={selectedGain.incasso}
                        onChange={(e) => setSelectedGain({ ...selectedGain, incasso: e.target.value })}
                      />
                      <p className="text-lg font-bold mt-2">Incasso B</p>
                      <input
                        type="number"
                        className="border border-gray-300 p-2 rounded"
                        value={selectedGain.incassoB}
                        onChange={(e) => setSelectedGain({ ...selectedGain, incassoB: e.target.value })}
                      />
                      <button className="bg-gray-800 text-white p-2 rounded mt-4" onClick={() => hadleUpdateGain(selectedGain)}>
                        Aggiorna
                      </button>
                      <button className="bg-gray-800 text-white p-2 rounded mt-4" onClick={() => setSelectedGain(null)}>
                        Annulla
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {turnPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center">
                  <div className="bg-white p-4 rounded">
                    <h2 className="text-2xl font-bold">Aggiungi turno</h2>
                    <div className="flex flex-col">
                      <select
                        className="border border-gray-300 p-2 rounded mt-4"
                        value={selectedOpening?.CF}
                        onChange={(e) => setSelectedOpening({ ...selectedOpening, CF: e.target.value })}
                      >
                        <option value="">Seleziona un commesso</option>

                        {employee
                          .filter((emp) => !selectedOpening.turni?.map((turno) => turno.CF).includes(emp.CF))
                          .map((employee) => (
                            <option key={employee.id} value={employee.CF}>
                              {employee.Nome} {employee.Cognome}
                            </option>
                          ))}
                      </select>
                      <button className="bg-gray-800 text-white p-2 rounded mt-4" onClick={() => handleAddTurn(selectedOpening)}>
                        Aggiungi
                      </button>
                      <button className="bg-gray-800 text-white p-2 rounded mt-4" onClick={() => setTurnPopup(false)}>
                        Annulla
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
