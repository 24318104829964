import React, { FC } from "react";
import { Sidebar, Menu, MenuItem, MenuItemStyles } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCartShopping,
  faRightFromBracket,
  faCalendarDays,
  faFile,
  faUser,
  faWeightHanging,
  faChartColumn,
  faClockRotateLeft,
  faFileInvoice,
  faShop,
  faHandHoldingDollar
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../AuthContext.tsx";

//@ts-ignore
import { RoutesDef } from "../../components/RoutesDef.tsx";
import { Link } from "react-router-dom";

import { SidebarHeader } from "./SidebarHeader.tsx";
import { Typography } from "./Typography.tsx";

library.add(
  faBars,
  faRightFromBracket,
  faCalendarDays,
  faFile,
  faWeightHanging,
  faChartColumn,
  faClockRotateLeft,
  faCartShopping,
  faFileInvoice,
  faUser,
  faShop,
  faHandHoldingDollar
);

interface CustomSidebarProps {
  toggled: boolean;
  setToggled: (value: boolean) => void;
  setBroken: (value: boolean) => void;
}

const CustomSidebar: FC<CustomSidebarProps> = ({ toggled, setToggled, setBroken }) => {
  const { logout } = useAuth();
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    button: {
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Sidebar
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
      breakPoint="lg"
      rootStyles={{
        color: "#ffff",
      }}
      backgroundColor="#1F2A40"
      width="230px"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <SidebarHeader style={{ marginBottom: "14px", marginTop: "16px" }} />
        <div style={{ flex: 1, marginBottom: "6px", marginTop: "16px" }}>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography variant="body2" fontWeight={600} style={{ letterSpacing: "0.5px" }} color="#fff">
              Controllo
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <Link to={RoutesDef.DASHBOARD}>
              <MenuItem icon={<FontAwesomeIcon icon={faChartColumn} />}>Dashboard</MenuItem>
            </Link>
            <Link to={RoutesDef.APERTURE}>
              <MenuItem icon={<FontAwesomeIcon icon={faCalendarDays} />}> Aperture</MenuItem>
            </Link>
            <Link to={RoutesDef.NEGOZIO}>
              <MenuItem icon={<FontAwesomeIcon icon={faShop} />}>Negozio</MenuItem>
            </Link>
            <Link to={RoutesDef.STORICO}>
              <MenuItem icon={<FontAwesomeIcon icon={faClockRotateLeft} />}> Storico</MenuItem>
            </Link>
            <Link to={RoutesDef.RIMBORSI}>
              <MenuItem icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}> Rimborsi</MenuItem>
            </Link>
            <Link to={RoutesDef.COMMESSI}>
              <MenuItem icon={<FontAwesomeIcon icon={faUser} />}> Commessi</MenuItem>
            </Link>
          </Menu>
          <div style={{ padding: "0 24px", marginBottom: "6px", marginTop: "16px" }}>
            <Typography variant="body2" fontWeight={600} style={{ letterSpacing: "0.5px" }}>
              Magazzino
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <Link to={RoutesDef.CARICO}>
              <MenuItem icon={<FontAwesomeIcon icon={faWeightHanging} />}> Carico</MenuItem>
            </Link>
            <Link to={RoutesDef.ORDINI}>
              <MenuItem icon={<FontAwesomeIcon icon={faCartShopping} />}> Ordini</MenuItem>
            </Link>
            <Link to={RoutesDef.CATALOGO}>
              <MenuItem icon={<FontAwesomeIcon icon={faFileInvoice} />}> Catalogo</MenuItem>
            </Link>
          </Menu>
          <div style={{ padding: "0 24px", marginBottom: "6px", marginTop: "16px" }}>
            <Typography variant="body2" fontWeight={600} style={{ letterSpacing: "0.5px" }}>
              Impostazioni
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <div onClick={() => logout()}>
              <MenuItem icon={<FontAwesomeIcon icon={faRightFromBracket} />}> Logout</MenuItem>
            </div>
          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default CustomSidebar;
