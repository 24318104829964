import React, { useEffect, useState, useCallback, useRef } from "react";
import CustomSidebar from "../CustomSidebar.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretUp, faCaretDown, faTimes, faSquareCaretUp, faSquareCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import { useAuth } from "../../../AuthContext.tsx";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

library.add(faTrash, faCaretUp, faCaretDown, faTimes, faSquareCaretUp, faSquareCaretDown);

export default function Ordini() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const { idToken, selectedShop } = useAuth();
  const [year, setYear] = useState([
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [orderPopup, setAddOrderPopup] = useState(false);
  const [articles, setArticles] = useState<Array<any>>([]);
  const [producer, setProducer] = useState<Array<any>>([]);
  const [isInfoOpen, setIsInfoOpen] = useState<Array<boolean>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [addDetailPopup, setAddDetailPopup] = useState(false);
  const [delateDetailPopup, setDelateDetailPopup] = useState<{ open: boolean; detailId: any }>({ open: false, detailId: {} });
  const [formDetail, setFormDetail] = useState({
    id: "",
    consegnato: false,
    quantita: "",
    D_A_id: "",
    D_A_CF: "",
  });
  const tmpSelectedShop = useRef<any>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInfo = (index: number) => {
    // Invertiamo il valore booleano per il prodotto all'indice specificato
    setIsInfoOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggla lo stato per l'elemento indicato
    }));
  };

  const handleAddOrder = async (id, data) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/addOrder",
          {
            id: id,
            data: data,
            Indirizzo: selectedShop?.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Ordine aggiunto con successo");
            setAddOrderPopup(false);
            console.log("selectedYear:", selectedYear);
            console.log("data:", data.split("-")[0]);
            if (selectedYear.toString() === data.split("-")[0].toString()) {
              getOrders();
            }
          } else {
            console.error(response.data);
            toast.error("Errore nell'aggiunta dell'ordine");
          }
        });
    } catch (error) {
      console.error("Errore nell'aggiunta dell'ordine:", error);
    }
  };

  const getArticles = async (cf) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/getArticleByProducer",
          {
            CF: cf,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            setArticles(response.data.result);
          } else {
            toast.error("Errore nel recupero degli articoli");
          }
        })
        .catch((error) => {
          console.error("Errore nel recupero degli articoli:", error);
        });
    } catch (error) {
      console.error("Errore nel recupero degli articoli:", error);
    }
  };

  const handleUpdateDetail = async (product) => {
    console.log("handleUpdateDetail:", product);
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/updateDetail",
          {
            D_A_CF: product.D_A_CF,
            D_A_id: product.D_A_id,
            id: product.id,
            consegnato: product.consegnato.toString() === "1" ? 1 : 0,
            quantita: product.quantita,
            Indirizzo: product.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Dettaglio modificato con successo");
            setOrders([]);
            getOrders();
          } else {
            console.error(response.data);
            toast.error("Errore nella modifica del dettaglio");
          }
        });
    } catch (error) {
      console.error("Errore nella modifica del dettaglio:", error);
    }
  };

  const handleDelateDetail = async (product) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/deleteDetail",
          {
            D_A_CF: product.D_A_CF,
            D_A_id: product.D_A_id,
            id: product.id,
            Indirizzo: product.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Dettaglio eliminato con successo");
            setAddDetailPopup(false);
            setOrders([]);
            getOrders();
          }
        });
    } catch (error) {
      console.error("Errore nell'eliminazione del dettaglio:", error);
    }
  };

  const handleDetail = async (product) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/deleteDetail",
          {
            D_A_CF: product.D_A_CF,
            D_A_id: product.D_A_id,
            id: product.D_A_id,
            Indirizzo: product.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Dettaglio eliminato con successo");
            setAddDetailPopup(false);
            setOrders([]);
            getOrders();
          }
        });
    } catch (error) {
      console.error("Errore nell'eliminazione del dettaglio:", error);
    }
  };

  const getProducer = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getProducer", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (!response.data.status) {
          toast.error("Errore nel caricamento dei produttori");
          return;
        }
        console.log("Produttori:", response.data.result);
        setProducer(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken]);

  const handleAddDetail = async () => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/addDetail",
          {
            id: formDetail.id,
            consegnato: formDetail.consegnato,
            quantita: formDetail.quantita,
            D_A_id: formDetail.D_A_id,
            D_A_CF: formDetail.D_A_CF,
            Indirizzo: selectedShop?.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Dettaglio aggiunto con successo");
            setAddDetailPopup(false);
            setOrders([]);
            getOrders();
          } else {
            console.error(response.data);
            toast.error("Errore nell'aggiunta del dettaglio");
          }
        });
    } catch (error) {
      console.error("Errore nell'aggiunta del dettaglio:", error);
    }
  };

  // TODO: GREAT FUNCTION - I WOULD LIKE UNDERSTAND IT
  const getOrders = useCallback(async () => {
    try {
      const orderResponse = await axios.post(
        "https://tommasoceredi.it/api/v1/getOrder",
        {
          anno: selectedYear,
          Indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (orderResponse.data.status) {
        const orders = orderResponse.data.result;
        console.log("Ordini:", orders);

        // Aggiungi una promessa per ogni ordine e raccogli i dettagli
        const orderDetailsPromises = orders.map(async (order) => {
          const detailResponse = await axios.post(
            "https://tommasoceredi.it/api/v1/getDetail",
            {
              id: order.id,
              Indirizzo: selectedShop?.Indirizzo,
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (detailResponse.data.status) {
            // Inserisci i dettagli nell'ordine
            order.dettagli = detailResponse.data.result;
          } else {
            toast.error(`Errore nel recupero dei dettagli per ordine ID: ${order.id}`);
          }
        });

        // Attendi tutte le promesse
        await Promise.all(orderDetailsPromises);

        // Ora gli ordini includono i dettagli
        console.log("Ordini con dettagli:", orders);
        setOrders(orders);
      } else {
        toast.error("Errore nel recupero degli ordini");
      }
    } catch (error) {
      console.error("Errore nel recupero degli ordini:", error);
    }
  }, [idToken, selectedYear, selectedShop]);

  useEffect(() => {
    if (orders.length === 0) {
      getOrders();
    }

    if (tmpSelectedShop.current !== selectedShop) {
      getOrders();
      tmpSelectedShop.current = selectedShop;
      console.log("Selected shop changed: ", selectedShop, " - ", tmpSelectedShop.current);
    }
  }, [getOrders, orders, selectedShop, getProducer]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className=" flex flex-col min-h-screen " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="flex justify-center">
              <div className="max-w-screen-xl w-full">
                <caption className="m-5 p-5 flex items-center rounded-lg justify-between text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  <div>
                    Lista degli ordini
                    <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                      Qui sono visualizzati tutti gli ordini effettuati. Seleziona un anno per visualizzare gli ordini di
                      quell'anno. Per aggiungere un ordine clicca sul pulsante "Aggiungi Ordine".
                    </p>
                  </div>
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                    onClick={() => setAddOrderPopup(true)}
                  >
                    Aggiungi Ordine
                  </button>
                </caption>

                <div className="relative shadow-md sm:rounded-lg mt-10 max-w-screen-lg mx-auto rounded-lg z-20">
                  <div className="flex items-center justify-between flex-column flex-wrap md:flex-row md:space-y-0 bg-white dark:bg-gray-900 rounded-lg">
                    <div className="relative">
                      <button
                        id="menu-button"
                        data-dropdown-toggle="dropdownAction"
                        className="m-4 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={toggleMenu}
                      >
                        <span className="sr-only">Action button</span>
                        {selectedYear}
                        <FontAwesomeIcon icon={isMenuOpen ? faCaretUp : faCaretDown} className="ml-2" />
                      </button>
                      <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        className={`z-50 ${
                          isMenuOpen ? "block" : "hidden"
                        } mt-2 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                      >
                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                          {year.length > 0 &&
                            year.map((anno, index) => (
                              <li key={index}>
                                <div
                                  onClick={() => {
                                    setSelectedYear(anno);
                                    toggleMenu();
                                    setOrders([]);
                                    getOrders();
                                  }}
                                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                >
                                  {anno}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-lg">
                      <tr>
                        <th scope="col" className="text-center align-middle px-6 py-3 w-1/4">
                          ID
                        </th>
                        <th scope="col" className="text-center align-middle px-6 py-3 w-2/4">
                          DATA
                        </th>
                        <th scope="col" className="text-center align-middle px-6 py-3 w-1/4">
                          <span className="sr-only">Show</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((ordine, index) => (
                        <React.Fragment key={index}>
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="text-center align-middle px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/4">
                              {ordine.id}
                            </td>
                            <td className="text-center align-middle px-6 py-4 hidden md:table-cell w-2/4">
                              {ordine.data.split("T")[0].split("-").reverse().join("-")}
                            </td>
                            <td className="text-center align-middle px-6 py-4 text-right w-1/4">
                              <FontAwesomeIcon
                                onClick={() => {
                                  toggleInfo(index);
                                  getProducer();
                                }}
                                className="cursor-pointer"
                                style={{ userSelect: "none" }} //non seleziona il testo
                                icon={isInfoOpen[index] ? faSquareCaretUp : faSquareCaretDown}
                              />
                            </td>
                          </tr>
                          {isInfoOpen[index] && (
                            <tr>
                              <td colSpan={3}>
                                <table className="w-full mt-4 text-sm text-gray-500 text-gray-200 max-w-screen-md rounded-lg items-center justify-between mx-auto">
                                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                      <th scope="col" className="text-center align-middle px-6 py-3">
                                        Produttore
                                      </th>
                                      <th scope="col" className="text-center align-middle px-6 py-3">
                                        Articolo
                                      </th>
                                      <th scope="col" className="text-center align-middle px-6 py-3">
                                        Quantità
                                      </th>
                                      <th scope="col" className="text-center align-middle px-6 py-3">
                                        Consegnato
                                      </th>
                                      <th scope="col" className="text-center align-middle px-6 py-3"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ordine.dettagli.map((dettaglio, index) => (
                                      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="text-center align-middle px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                          {producer.find((p) => p.CF === dettaglio.D_A_CF)?.Nome}
                                        </td>
                                        <td className="text-center align-middle px-6 hidden md:table-cell">{dettaglio.nome}</td>
                                        <td className="text-center align-middle px-6 hidden md:table-cell">
                                          {dettaglio.quantita}
                                        </td>
                                        <td className="text-center align-middle px-6 hidden md:table-cell">
                                          <div className="p-5">
                                            <div
                                              className={`relative inline-flex items-center cursor-pointer`}
                                              onClick={() => {
                                                console.log("Dettaglio:", dettaglio);
                                                handleUpdateDetail({
                                                  D_A_CF: dettaglio.D_A_CF,
                                                  D_A_id: dettaglio.D_A_id,
                                                  id: dettaglio.id,
                                                  consegnato: dettaglio.consegnato > 0 ? 0 : 1,
                                                  quantita: dettaglio.quantita,
                                                  Indirizzo: dettaglio.Indirizzo,
                                                });
                                              }}
                                            >
                                              <div
                                                className={`block w-10 h-6 rounded-full transition-colors ${
                                                  dettaglio.consegnato > 0 ? "bg-yellow-600" : "bg-gray-300 dark:bg-gray-600"
                                                }`}
                                              ></div>
                                              <div
                                                className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition ${
                                                  dettaglio.consegnato > 0 ? "translate-x-4" : ""
                                                }`}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="text-center align-middle px-6 py-4 hidden md:table-cell">
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            onClick={() => {
                                              setDelateDetailPopup({ open: true, detailId: dettaglio });
                                            }}
                                            className="w-full py-2 cursor-pointer border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 focus:ring-offset-yellow-600"
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <button
                                    onClick={() => {
                                      getProducer();
                                      setAddDetailPopup(true);
                                      formDetail.id = ordine.id;
                                    }}
                                    className="w-full mb-10 mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 focus:ring-offset-yellow-600"
                                  >
                                    Aggiungi Dettaglio
                                  </button>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {delateDetailPopup.open && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="relative bg-white w-96 dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center justify-between p-5 border-b dark:border-gray-700">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Elimina Dettaglio</h3>
                  <button
                    onClick={() => setDelateDetailPopup({ open: false, detailId: {} })}
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="p-5">
                  <p className="text-sm font-medium text-gray-700 dark:text-gray-400">
                    Sei sicuro di voler eliminare il dettaglio?
                  </p>
                  <button
                    onClick={() => {
                      handleDelateDetail(delateDetailPopup.detailId);
                      setDelateDetailPopup({ open: false, detailId: {} });
                    }}
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2 focus:ring-offset-red-600"
                  >
                    Elimina
                  </button>
                </div>
              </div>
            </div>
          )}

          {orderPopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="relative bg-white w-96 dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center justify-between p-5 border-b dark:border-gray-700">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Aggiungi Ordine</h3>
                  <button
                    onClick={() => setAddOrderPopup(false)}
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="p-5">
                  <label htmlFor="id" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    ID
                  </label>
                  <input
                    type="number"
                    id="idOrder"
                    name="id"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                  />
                </div>
                <div className="p-5">
                  <label htmlFor="data" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    data
                  </label>
                  <input
                    type="date"
                    id="dataOrder"
                    name="data"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                  />
                </div>
                <div className="p-5">
                  <button
                    onClick={() => {
                      const idElement = document.getElementById("idOrder") as HTMLInputElement;
                      const dataElement = document.getElementById("dataOrder") as HTMLInputElement;
                      if (idElement && dataElement) {
                        handleAddOrder(idElement.value, dataElement.value);
                      } else {
                        toast.error("ID non trovato");
                      }
                    }}
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2 focus:ring-offset-green-600"
                  >
                    Aggiungi
                  </button>
                </div>
              </div>
            </div>
          )}

          {addDetailPopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="relative bg-white w-96 dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center justify-between p-5 border-b dark:border-gray-700">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Aggiungi Dettaglio</h3>
                  <button
                    onClick={() => setAddDetailPopup(false)}
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="flex flex-row">
                  <div className="p-5">
                    <label htmlFor="id" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                      ID
                    </label>
                    <input
                      type="number"
                      id="idDetail"
                      name="id"
                      value={formDetail.id}
                      readOnly
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                    />
                  </div>
                  <div className="p-5">
                    <label htmlFor="blisterato" className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-2">
                      Consegnato
                    </label>
                    <div
                      className={`relative inline-flex items-center cursor-pointer`}
                      onClick={() => setFormDetail({ ...formDetail, consegnato: !formDetail.consegnato })}
                    >
                      <div
                        className={`block w-10 h-6 rounded-full transition-colors ${
                          formDetail.consegnato ? "bg-green-600" : "bg-gray-300 dark:bg-gray-600"
                        }`}
                      ></div>
                      <div
                        className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition ${
                          formDetail.consegnato ? "translate-x-4" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="p-5">
                  <label htmlFor="produttore" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    Produttore
                  </label>
                  <select
                    id="produttoreDetail"
                    name="produttore"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                    onChange={(e) => {
                      getArticles(e.target.value);
                      setFormDetail({ ...formDetail, D_A_CF: e.target.value });
                    }}
                  >
                    <option value="">Seleziona un produttore</option>
                    {producer.map((produttore, index) => (
                      <option key={index} value={produttore.CF}>
                        {produttore.Nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="p-5">
                  <label htmlFor="articolo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    Articolo
                  </label>
                  <select
                    id="articoloDetail"
                    name="articolo"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                    onChange={(e) => setFormDetail({ ...formDetail, D_A_id: e.target.value })}
                  >
                    <option value="">Seleziona un articolo</option>
                    {articles.map((articolo, index) => (
                      <option key={index} value={articolo.id}>
                        {articolo.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="p-5">
                  <label htmlFor="quantita" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    Quantità
                  </label>
                  <input
                    onChange={(e) => setFormDetail({ ...formDetail, quantita: e.target.value })}
                    type="number"
                    id="quantitaDetail"
                    name="quantita"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-green-300 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-800 dark:focus:border-green-800"
                  />
                </div>
                <div className="p-5">
                  <button
                    onClick={() => {
                      handleAddDetail();
                    }}
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2 focus:ring-offset-green-600"
                  >
                    Aggiungi
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
