import React, { useEffect, useState, useCallback } from "react";
import CustomSidebar from "../CustomSidebar.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCaretLeft, faCaretRight, faGear, faCircle } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import axios from "axios";
import { useAuth } from "../../../AuthContext.tsx";
import toast, { Toaster } from "react-hot-toast";
library.add(faBars, faCaretLeft, faCaretRight, faGear, faCircle);

export default function Negozio() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [showPopupAddWarehouse, setShowPopupAddWarehouse] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupLicense, setShowPopupLicense] = useState(false);
  const [showPopupCategory, setShowPopupCategory] = useState(false);
  const [categorie, setCategorie] = useState<{ id: string; nome: string; peso: string }[]>([]);
  const [licenze, setLicenze] = useState<{ id: string; numero: string; scadenza: string; nome: string; peso: string }[]>([]);
  const [showPopupWarehouse, setShowPopupWarehouse] = useState<{
    show: boolean;
    warehouse: { indirizzo: string; metratura: null } | null;
  }>({ show: false, warehouse: null });
  const [formData, setFormData] = useState({
    nome: "",
    indirizzo: "",
    metratura: "",
    disIndirizzo: "",
  });
  const [formLicense, setFormLicense] = useState({
    numero: "",
    scadenza: "",
    Indirizzo: "",
    nome: "",
    peso: "",
  });
  const [magazzini, setMagazzini] = useState<{ id: string; metratura: string; indirizzo: string }[]>([]);
  const { idToken, selectedShop } = useAuth();
  const [expandedShopId, setExpandedShopId] = useState<string | null>(null);
  const [shops, setShops] = useState<{ id: string; Nome: string; Indirizzo: string }[]>([]);
  const [visibleLicenses, setVisibleLicenses] = useState(4);

  const handleShowMore = () => {
    setVisibleLicenses((prev) => prev + 4);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleExpand = (shopId: string) => {
    setExpandedShopId((prev) => (prev === shopId ? null : shopId));
  };

  const toggleAddWarehouse = (shopName, shopIndirizzo) => {
    setShowPopupAddWarehouse(true);
    setFormData({
      nome: shopName,
      indirizzo: "",
      metratura: "",
      disIndirizzo: shopIndirizzo,
    });
  };

  const togglePopupWarehouse = (warehouse) => {
    if (showPopupWarehouse.show) {
      setShowPopupWarehouse({ show: false, warehouse: null });
    } else {
      setShowPopupWarehouse({ show: true, warehouse: warehouse });
    }
    console.log(warehouse);
  };

  const resetForm = () => {
    setFormData({ nome: "", indirizzo: "", metratura: "", disIndirizzo: "" });
    setFormLicense({ numero: "", scadenza: "", Indirizzo: "", nome: "", peso: "" });
  };

  const handleSubmitShop = () => {
    console.log(formData);
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addShop",
        {
          nome: formData.nome,
          indirizzo: formData.indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Negozio aggiunto con successo");

          setShowPopup(false);
          resetForm();
          getShops();
        } else {
          toast.error("Errore nell'aggiunta del negozio");
          console.error(response.data);
        }
      })
      .catch((error) => {
        toast.error("Errore nell'aggiunta del negozio");
        console.error(error);
      });
  };

  const toggleAddLicense = (warehouse) => {
    setShowPopupLicense(true);
    setFormLicense({
      numero: "",
      scadenza: "",
      Indirizzo: warehouse.indirizzo,
      nome: "",
      peso: "",
    });
  };

  const handleSubmitLicense = () => {
    console.log(formLicense);
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addLicense",
        {
          numero: formLicense.numero,
          scadenza: formLicense.scadenza,
          indirizzo: formLicense.Indirizzo,
          nome: formLicense.nome,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Licenza aggiunta con successo");
          console.log(response.data);
          setShowPopupLicense(false);
          resetForm();
          getLicense(formLicense.Indirizzo);
          getCategorie();
        } else {
          toast.error("Errore nell'aggiunta della licenza");
          console.error(response.data);
        }
      })
      .catch((error) => {
        toast.error("Errore nell'aggiunta della licenza");
        console.error(error);
      });
  };

  const getLicense = async (indirizzo) => {
    setLicenze([]);
    try {
      const response = await axios.post(
        "https://tommasoceredi.it/api/v1/getLicense",
        {
          indirizzo: indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log(response.data);
      const formattedLicenses = response.data.result
        .map((license) => ({
          id: license.numero,
          numero: license.numero,
          scadenza: license.scadenza,
          nome: license.nome,
        }))
        .sort((a, b) => new Date(b.scadenza).getTime() - new Date(a.scadenza).getTime());
      setLicenze(formattedLicenses);
    } catch (error) {
      console.error("Errore nel recupero delle licenze:", error);
    }
  };

  const handleSubmitCategory = () => {
    console.log(formData);
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addCategory",
        {
          nome: formLicense.nome,
          peso: formLicense.peso,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Categoria aggiunta con successo");
          console.log(response.data);
          setShowPopupCategory(false);
          resetForm();
        } else {
          toast.error("Errore nell'aggiunta della categoria");
          console.error(response.data);
        }
      })
      .catch((error) => {
        toast.error("Errore nell'aggiunta della categoria");
        console.error(error);
      });
  };

  const getCategorie = async () => {
    try {
      const response = await axios.get("https://tommasoceredi.it/api/v1/getCategory", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const formattedCategories = response.data.result.map((category) => ({
        id: category.nome,
        nome: category.nome,
        peso: category.peso,
      }));
      setCategorie(formattedCategories);
      console.log(categorie);
    } catch (error) {
      console.error("Errore nel recupero delle categorie:", error);
    }
  };

  const handleSubmitWarehouse = () => {
    console.log(formData);
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addWarehouse",
        {
          metratura: formData.metratura,
          indirizzo: formData.indirizzo,
          disIndirizzo: formData.disIndirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Magazzino aggiunto con successo");
          console.log(response.data);
          setShowPopupAddWarehouse(false);
          resetForm();
          getWarehouse(formData.disIndirizzo);
        } else {
          toast.error("Errore nell'aggiunta del magazzino");
          console.error(response.data);
        }
      })
      .catch((error) => {
        toast.error("Errore nell'aggiunta del magazzino");
        console.error(error);
      });
  };

  // con POST ottengo i dati dal server
  const getWarehouse = async (indirizzo) => {
    setMagazzini([]); // Resetta lo stato
    try {
      const response = await axios.post(
        "https://tommasoceredi.it/api/v1/getWarehouse",
        {
          disIndirizzo: indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log(response.data);
      const formattedWarehouses = response.data.result.map((warehouse) => ({
        id: warehouse.Indirizzo,
        metratura: warehouse.Metratura,
        indirizzo: warehouse.Indirizzo,
      }));
      setMagazzini(formattedWarehouses); // Sovrascrive lo stato con i nuovi dati
    } catch (error) {
      console.error("Errore nel recupero dei magazzini:", error);
    }
  };

  const getShops = useCallback(async () => {
    try {
      const response = await axios.get("https://tommasoceredi.it/api/v1/getShop", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const formattedShops = response.data.result.map((shop) => ({
        id: shop.Indirizzo,
        Nome: shop.Nome,
        Indirizzo: shop.Indirizzo,
      }));
      setShops(formattedShops); // Sovrascrive lo stato con i nuovi dati
      console.log(formattedShops);
    } catch (error) {
      console.error("Errore nel recupero dei negozi:", error);
    }
  }, [idToken]);

  useEffect(() => {
    getShops();
  }, [getShops]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className="min-h-screen flex flex-col  " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="max-w-screen-xl w-full mx-auto">
              <h1 className="text-2xl text-white p-5">Negozi disponibili</h1>
              <div className="flex justify-start items-center pt-1 pl-5">
                <button className="bg-yellow-500 p-2 rounded-md" onClick={() => setShowPopup(true)}>
                  AGGIUNGI NEGOZIO
                </button>
              </div>

              {showPopupCategory && (
                <div className="p-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-5 rounded-md">
                    <h2 className="text-xl mb-4">Aggiungi Categoria</h2>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      value={formLicense.nome}
                      onChange={(e) => setFormLicense({ ...formLicense, nome: e.target.value })}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <input
                      type="text"
                      name="peso"
                      placeholder="Peso"
                      value={formLicense.peso}
                      onChange={(e) => setFormLicense({ ...formLicense, peso: e.target.value })}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <button className="bg-blue-500 p-2 rounded-md text-white" onClick={handleSubmitCategory}>
                      Invia
                    </button>
                    <button
                      className="bg-red-500 p-2 rounded-md text-white ml-2"
                      onClick={() => {
                        setShowPopupCategory(false);
                        resetForm();
                      }}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              )}

              {showPopup && (
                <div className="p-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-5 rounded-md">
                    <h2 className="text-xl mb-4">Aggiungi Negozio</h2>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      value={formData.nome}
                      onChange={handleInputChange}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <input
                      type="text"
                      name="indirizzo"
                      placeholder="Indirizzo"
                      value={formData.indirizzo}
                      onChange={handleInputChange}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <button className="bg-blue-500 p-2 rounded-md text-white" onClick={handleSubmitShop}>
                      Invia
                    </button>
                    <button
                      className="bg-red-500 p-2 rounded-md text-white ml-2"
                      onClick={() => {
                        setShowPopup(false);
                        resetForm();
                      }}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              )}

              {showPopupWarehouse.show && showPopupWarehouse.warehouse && (
                <div className="p-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
                  <div className="bg-white p-5 rounded-md">
                    <h2 className="text-xl mb-4">Magazzino: {showPopupWarehouse.warehouse.indirizzo}</h2>
                    <div className="flex items-center">
                      <p className="mr-2">Licenze:</p>
                      <button
                        onClick={() => {
                          toggleAddLicense(showPopupWarehouse.warehouse);
                          getCategorie();
                          setShowPopupAddWarehouse(false);
                        }}
                        className="bg-yellow-500 p-0.5 rounded-md text-white ml-4"
                      >
                        AGGIUNGI
                      </button>
                    </div>
                    <div className="flex items-center mt-2">
                      {/** Tabella con le licenze. colonna NOME SCADENZA STATO */}
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th className="border text-center">Numero</th>
                            <th className="border text-center">Nome</th>
                            <th className="border text-center">Scadenza</th>
                            <th className="border text-center">Stato</th>
                          </tr>
                        </thead>
                        <tbody>
                          {licenze.length > 0 &&
                            licenze.slice(0, visibleLicenses).map(
                              (
                                license // Mostra solo le licenze visibili
                              ) => (
                                <tr key={license.id}>
                                  <td className="border">{license.numero}</td>
                                  <td className="border text-center">{license.nome}</td>
                                  <td className="border text-center">
                                    {license.scadenza.split("T")[0].split("-").reverse().join("/")}
                                  </td>
                                  <td className="border text-center w-10">
                                    {new Date(license.scadenza) < new Date() ? (
                                      <FontAwesomeIcon icon={faCircle} className="text-red-500" />
                                    ) : (
                                      <FontAwesomeIcon icon={faCircle} className="text-green-500" />
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    {licenze.length > 4 && visibleLicenses < licenze.length && (
                      <div className="flex justify-end">
                        <button className="bg-blue-500 p-1 rounded-md text-white" onClick={handleShowMore}>
                          Mostra altro
                        </button>
                      </div>
                    )}
                    <div className="flex items-center mt-2">
                      <p className="mr-2">Metratura:</p>
                      <p>{showPopupWarehouse.warehouse.metratura} mq</p>
                    </div>
                    <div className="flex items-center mt-2 mb-2">
                      <p className="mr-2">Elimina magazzino:</p>
                      <button className="bg-red-500 p-0.5 rounded-md text-white">Elimina</button>
                    </div>
                    <button
                      className="p-2 rounded-md text-white bg-blue-500"
                      onClick={() => {
                        setShowPopupWarehouse({ show: false, warehouse: null });
                        resetForm();
                      }}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              )}

              {showPopupLicense && (
                <div className="p-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
                  <div className="bg-white p-5 rounded-md">
                    <h2 className="text-xl mb-4">Aggiungi Licenza</h2>
                    <input
                      type="text"
                      name="numero"
                      placeholder="Numero"
                      value={formLicense.numero}
                      onChange={(e) => setFormLicense({ ...formLicense, numero: e.target.value })}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <input
                      type="date"
                      name="scadenza"
                      placeholder="Scadenza"
                      value={formLicense.scadenza}
                      onChange={(e) => setFormLicense({ ...formLicense, scadenza: e.target.value })}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <div className="flex items-center">
                      <select
                        name="nome"
                        value={formLicense.nome}
                        onChange={(e) => setFormLicense({ ...formLicense, nome: e.target.value })}
                        className="mb-2 p-2 border rounded-md w-full"
                      >
                        <option value="">Seleziona tipo</option>
                        {categorie.map((category) => (
                          <option key={category.nome} value={category.nome}>
                            {category.nome} - {category.peso}kg
                          </option>
                        ))}
                      </select>
                      <button className="bg-yellow-500 p-1 rounded-md text-white ml-2" onClick={() => setShowPopupCategory(true)}>
                        AGGIUNGI
                      </button>
                    </div>
                    <button className="bg-blue-500 p-2 rounded-md text-white" onClick={handleSubmitLicense}>
                      Invia
                    </button>
                    <button
                      className="bg-red-500 p-2 rounded-md text-white ml-2"
                      onClick={() => {
                        setShowPopupLicense(false);
                        resetForm();
                      }}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              )}

              {showPopupAddWarehouse && (
                <div className="p-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
                  <div className="bg-white p-5 rounded-md">
                    <h2 className="text-xl mb-4">Aggiungi Magazzino</h2>
                    <input
                      type="number"
                      name="metratura"
                      placeholder="Metratura"
                      value={formData.metratura}
                      onChange={handleInputChange}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <input
                      type="text"
                      name="indirizzo"
                      placeholder="Indirizzo"
                      value={formData.indirizzo}
                      onChange={handleInputChange}
                      className="mb-2 p-2 border rounded-md w-full"
                    />
                    <button className="bg-blue-500 p-2 rounded-md text-white" onClick={handleSubmitWarehouse}>
                      Invia
                    </button>
                    <button
                      className="bg-red-500 p-2 rounded-md text-white ml-2"
                      onClick={() => {
                        setShowPopupAddWarehouse(false);
                        resetForm();
                      }}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              )}

              <div className="p-5 grid grid-cols-1 gap-4">
                {shops?.length > 0 &&
                  shops.map((shop) => (
                    <div key={shop.id} className="bg-white p-4 rounded-md shadow-md max-w-4xl relative">
                      <h3 className="text-lg font-bold">{shop.Nome}</h3>
                      <button
                        className="absolute inset-y-0 right-0 bg-gray-200 p-1 rounded w-20 text-center"
                        onClick={async () => {
                          toggleExpand(shop.id);
                          await getWarehouse(shop.Indirizzo);
                        }}
                      >
                        {expandedShopId === shop.id ? "Nascondi" : "Espandi"}
                      </button>
                      {expandedShopId === shop.id && (
                        <div className="mt-2">
                          <p>{shop.Indirizzo}</p>
                          <p className="mt-2 font-bold">Magazzini:</p>
                          <div className="items-center">
                            {magazzini.length > 0 &&
                              magazzini.map((warehouse) => (
                                <div
                                  key={warehouse.id}
                                  className="flex p-1 rounded-md items-center bg-gray-100 mt-2 max-w-44 justify-between"
                                >
                                  <p>{warehouse.indirizzo}</p>
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      togglePopupWarehouse(warehouse);
                                      getLicense(warehouse.indirizzo);
                                    }}
                                    icon={faGear}
                                    className="text-gray-500 ml-2 cursor-pointer"
                                  />
                                </div>
                              ))}
                          </div>
                          <button
                            className="bg-yellow-500 p-1 rounded-md text-white mt-2 text-sm"
                            onClick={() => toggleAddWarehouse(shop.Nome, shop.Indirizzo)}
                          >
                            AGGIUNGI
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
