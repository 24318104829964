import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { RoutesDef } from "../../../components/RoutesDef.tsx";
import CustomSidebar from "../CustomSidebar.tsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareCaretDown,
  faSquareCaretUp,
  faCaretUp,
  faCaretDown,
  faTrash,
  faBoxArchive,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../AuthContext.tsx";
import NavAdmin from "../NavAdmin.tsx";
import toast, { Toaster } from "react-hot-toast";

library.add(
  faBars,
  faTrash,
  faRightFromBracket,
  faSquareCaretDown,
  faSquareCaretUp,
  faEye,
  faEyeSlash,
  faCaretUp,
  faCaretDown,
  faBoxArchive
);

export default function Carico() {
  const { idToken, selectedShop } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuWarehouse, setMenuWarehouse] = useState(false);
  const [warehouse, setWarehouse] = useState<any[]>([]);
  const [addProductPopup, setAddProductPopup] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>();
  const [category, setCategory] = useState<any[]>([]);
  const [delatePopup, setDelatePopup] = useState<{ open: boolean; productId: any }>({ open: false, productId: {} });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [articles, setArticles] = useState<any[]>([]);
  const [producer, setProducer] = useState<any[]>([]);
  const [formProduct, setFormProduct] = useState({
    Der_CF: "",
    Der_id: "",
    quantita: 0,
    categoria: "",
    blister: false,
    nome: "",
    massa_attiva: 0,
    id: "",
    tag: "",
  });
  const [carico, setCarico] = useState<any[]>([]);
  const tmpSelectedShop = useRef<any>(null);

  const handleDeleteProduct = async (product) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/deleteProduct",
          {
            Indirizzo: product.Indirizzo,
            Der_CF: product.Der_CF,
            Der_id: product.Der_id,
            categoria: product.categoria,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Articolo eliminato correttamente");
            setDelatePopup({ open: false, productId: {} });
            getProduct(selectedWarehouse.id);
          } else {
            toast.error("Errore nell'eliminazione dell'articolo");
            console.error("Errore nell'eliminazione dell'articolo:", response.data);
          }
        })
        .catch((error) => {
          console.error("Errore nell'eliminazione dell'articolo:", error);
        });
    } catch (error) {
      console.error("Errore nell'eliminazione dell'articolo:", error);
    }
  };

  const handleUpdateProduct = async (product) => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/updateProduct",
          {
            Der_CF: product.Der_CF,
            Der_id: product.Der_id,
            Indirizzo: product.Indirizzo,
            quantita: product.quantita,
            categoria: product.categoria,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Articolo modificato correttamente");
            getProduct(selectedWarehouse.id);
          } else {
            toast.error("Errore nella modifica dell'articolo");
            console.error("Errore nella modifica dell'articolo:", response.data);
          }
        })
        .catch((error) => {
          console.error("Errore nella modifica dell'articolo:", error);
        });
    } catch (error) {
      console.error("Errore nella modifica dell'articolo:", error);
    }
  };

  const getCategorie = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getCategory", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (!response.data.status) {
          toast.error("Errore nel caricamento delle categorie");
          return;
        }
        setCategory(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken]);

  const getArticle = useCallback(
    async (CF) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/getUniqueArticleByProducer",
          {
            CF: CF,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (!response.data.status) {
            toast.error("Errore nel caricamento degli articoli");
            return;
          }
          console.log(response.data.result);
          setArticles(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [idToken]
  );

  const getProduct = useCallback(
    async (id) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/getProduct",
          {
            Indirizzo: id,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          console.log("getProduct:", selectedWarehouse);
          console.log(response.data);
          if (!response.data.status) {
            toast.error("Errore nel caricamento degli articoli");
            return;
          }
          if (response.data.result.length === 0) {
            setCarico([]);
            return;
          }
          setCarico(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [idToken, selectedWarehouse]
  );

  const getWarehouse = useCallback(async () => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/getWarehouse",
          {
            disIndirizzo: selectedShop?.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            if (response.data.result.length > 0) {
              const data = response.data.result.map((shop) => {
                return {
                  id: shop.Indirizzo,
                  Dis_Inidirizzo: shop.Dis_Indirizzo,
                };
              });
              setWarehouse(data);
              console.log(data);
              if (selectedWarehouse === undefined) {
                setSelectedWarehouse(data[0]);
                getProduct(data[0].id);
              }
            } else {
              setSelectedWarehouse(null);
              setWarehouse([]);
            }
          } else {
            toast.error("Errore nel recupero degli articoli");
          }
        })
        .catch((error) => {
          console.error("Errore nel recupero degli articoli:", error);
        });
    } catch (error) {
      console.error("Errore nel recupero degli articoli:", error);
    }
  }, [idToken, selectedShop, selectedWarehouse, getProduct]);

  const handleAddProduct = async () => {
    try {
      await axios
        .post(
          "https://tommasoceredi.it/api/v1/addProduct",
          {
            Der_CF: formProduct.Der_CF,
            Der_id: formProduct.Der_id,
            Indirizzo: selectedWarehouse.id,
            quantita: formProduct.quantita,
            categoria: formProduct.categoria,
            blister: formProduct.blister,
            nome: formProduct.nome,
            massa_attiva: formProduct.massa_attiva,
            id: formProduct.id,
            tag: formProduct.tag,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Articolo aggiunto correttamente");
            getWarehouse();
            getProduct(selectedWarehouse.id);
            setAddProductPopup(false);
            setFormProduct({
              Der_CF: "",
              Der_id: "",
              quantita: 0,
              categoria: "",
              blister: false,
              nome: "",
              massa_attiva: 0,
              id: "",
              tag: "",
            });
          } else {
            toast.error("Errore nell'aggiunta dell'articolo");
            console.error("Errore nell'aggiunta dell'articolo:", response.data);
          }
        })
        .catch((error) => {
          console.error("Errore nell'aggiunta dell'articolo:", error);
        });
    } catch (error) {
      console.error("Errore nell'aggiunta dell'articolo:", error);
    }
  };

  const getProducer = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getProducer", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (!response.data.status) {
          toast.error("Errore nel caricamento dei produttori");
          return;
        }
        setProducer(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (category) => {
    setSelectedCategory(category); // Imposta la tipologia selezionata
    setIsMenuOpen(false); // Chiudi il menu
    console.log(filteredProducts);
  };

  useEffect(() => {
    if (selectedShop && selectedWarehouse === undefined) {
      getWarehouse();
      getCategorie();
      getProducer();
    }

    if (tmpSelectedShop.current !== selectedShop) {
      setSelectedWarehouse(undefined);
      setCarico([]);
      tmpSelectedShop.current = selectedShop;
      console.log("Selected shop changed: ", selectedShop, " - ", tmpSelectedShop.current);
    }
  }, [selectedShop, getWarehouse, getCategorie, getProducer, selectedWarehouse]);

  const macroOrder = (tag) => {
    if (tag.startsWith("Petardo:")) return 1;
    if (tag.startsWith("Batteria:")) return 2;
    if (tag.startsWith("Fontana:")) return 3;
    return 4;
  };

  const macroColor = (tag) => {
    if (tag.startsWith("Petardo:")) return "bg-yellow-500";
    if (tag.startsWith("Batteria:")) return "bg-yellow-400";
    if (tag.startsWith("Fontana:")) return "bg-yellow-300";
    return "bg-yellow-200";
  };

  const extractNumber = (tag) => {
    const match = tag.match(/(\d+(\.\d+)?)/);
    return match ? parseFloat(match[0]) : Infinity;
  };

  const filteredProducts = (selectedCategory ? carico.filter((product) => product.categoria === selectedCategory) : carico).sort(
    (a, b) => {
      const orderA = macroOrder(a.tag);
      const orderB = macroOrder(b.tag);

      if (orderA !== orderB) return orderA - orderB; // Ordine per tipo (Petardo -> Batteria -> Fontana)

      return extractNumber(a.tag) - extractNumber(b.tag); // Ordine crescente dentro ogni tipo
    }
  );

  //const filteredProducts = selectedCategory ? carico.filter((product) => product.categoria === selectedCategory) : carico;

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className=" flex flex-col min-h-screen " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />

            <div className="flex justify-center">
              <div className="max-w-screen-xl w-full">
                <div className="relative overflow-x-auto mb-14 mt-10 shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                      Carico magazzino: {selectedWarehouse?.id}{" "}
                      <FontAwesomeIcon
                        icon={menuWarehouse ? faCaretUp : faCaretDown}
                        onClick={() => {
                          getWarehouse();
                          setMenuWarehouse(!menuWarehouse);
                        }}
                      />
                      <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                        Qui è presente il carico del tuo magazzino. Tieni sotto controllo le tue scorte per non oltrepassare i
                        limiti consentiti. Per poter aggiungere un articolo deve essere prima stato creato nella sezione{" "}
                        <Link className="underline decoration-dotted cursor-pointer" to={RoutesDef.CATALOGO}>
                          Catalogo
                        </Link>
                      </p>
                    </caption>
                  </table>
                </div>
                {menuWarehouse && (
                  <div className="absolute -mt-28 bg-slate-900 border border-gray-200 border-gray-700 p-2 rounded shadow-lg w-52 z-50">
                    {warehouse.map((shop) => (
                      <div
                        key={shop.id}
                        className="cursor-pointer hover:bg-gray-100 hover:bg-gray-700 p-2 rounded"
                        onClick={() => {
                          setSelectedWarehouse(shop);
                          setMenuWarehouse(false);
                          getProduct(shop.id);
                        }}
                      >
                        <p className="text-md text-white">{shop.id}</p>
                      </div>
                    ))}
                  </div>
                )}
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
                    <div>
                      <button
                        id="menu-button"
                        data-dropdown-toggle="dropdownAction"
                        className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={toggleMenu}
                      >
                        <span className="sr-only">Action button</span>
                        {selectedCategory === "" ? "Tipologia" : selectedCategory}
                        <svg
                          className="w-2.5 h-2.5 ms-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>

                      <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        className={`z-10 ${
                          isMenuOpen ? "block" : "hidden"
                        } mt-2 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                      >
                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                          <li>
                            <div
                              onClick={() => handleMenuItemClick("")}
                              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                            >
                              Tutti
                            </div>
                          </li>
                          {category.map((category) => (
                            <li key={category.id}>
                              <div
                                onClick={() => handleMenuItemClick(category.nome)}
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                              >
                                {category.nome}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <button onClick={() => setAddProductPopup(true)} className="bg-blue-500 text-white py-2 px-4 rounded-lg">
                      Aggiungi prodotto
                    </button>
                  </div>

                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 text-black">
                    <thead className="text-xs border-b dark:border-gray-700 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 text-black">
                      <tr>
                        <th scope="col" className="px-6 py-3 bg-sky-500">
                          Nome
                        </th>
                        <th scope="col" className="px-6 py-3 bg-sky-400 hidden md:table-cell">
                          <div className="flex items-center">Codice</div>
                        </th>
                        <th scope="col" className="px-3 py-3 bg-white text-black">
                          <div className="flex items-center">Giacenza</div>
                        </th>
                        <th scope="col" className="px-4 py-3 bg-sky-400 hidden md:table-cell">
                          <div className="flex items-center">Massa attiva</div>
                        </th>
                        <th scope="col" className="px-6 py-3 bg-green-500 hidden md:table-cell">
                          <div className="flex items-center">Totale</div>
                        </th>
                        <th scope="col" className="px-6 py-3 bg-sky-500 hidden md:table-cell">
                          <div className="flex items-center"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredProducts.map((prodotto, index) => (
                        <React.Fragment key={index}>
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="px-6 py-4 bg-yellow-500 font-medium whitespace-nowrap ">
                              {prodotto.nome}{" "}
                              {prodotto.categoria === "5D - MAGAZZINO" && selectedCategory !== "5D - MAGAZZINO" ? (
                                <FontAwesomeIcon icon={faBoxArchive} />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="px-6 py-4 bg-yellow-400 hidden md:table-cell">{prodotto.Der_id}</td>
                            <td className="px-3 py-4 bg-white">
                              <input
                                type="number"
                                value={prodotto.quantita}
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  handleUpdateProduct({ ...prodotto, quantita: Number(e.target.value) });
                                }}
                                className="w-full py-2 mt-1 text-sm"
                              />
                            </td>
                            <td className="px-4 py-4 bg-yellow-400 hidden md:table-cell">
                              {prodotto?.blister === "1"
                                ? (prodotto?.massa_attiva / 2).toFixed(2)
                                : (prodotto?.massa_attiva).toFixed(2)}
                              g
                            </td>
                            <td className="px-6 py-4 bg-green-500 hidden md:table-cell">
                              {prodotto?.blister === "1"
                                ? ((prodotto?.massa_attiva / 2) * prodotto?.quantita).toFixed(2)
                                : (prodotto?.massa_attiva * prodotto?.quantita).toFixed(2)}
                              g
                            </td>
                            <td className="bg-sky-500 hidden md:table-cell text-center align-middle">
                              <button
                                onClick={() => {
                                  setDelatePopup({ open: true, productId: prodotto });
                                }}
                                className="flex justify-center items-center bg-sky-500 text-white py-2 px-4 rounded-lg"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      {selectedCategory !== "" && (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td className="px-6 py-4 bg-yellow-500 font-medium whitespace-nowrap ">Totale</td>
                          <td className="px-6 py-4 bg-yellow-400 hidden md:table-cell"></td>
                          <td className="px-3 py-4 bg-white"></td>
                          <td className="px-4 py-4 bg-yellow-400 hidden md:table-cell"></td>
                          <td className="px-6 py-4 bg-green-500 hidden md:table-cell">
                            {(
                              carico
                                .filter((prodotto) => prodotto?.categoria === selectedCategory) // Filtra per categoria
                                .reduce((acc, prodotto) => {
                                  return (
                                    acc +
                                    (prodotto?.blister === "1"
                                      ? (prodotto?.massa_attiva / 2) * prodotto?.quantita
                                      : prodotto?.massa_attiva * prodotto?.quantita)
                                  );
                                }, 0) / 1000
                            ).toFixed(2)}{" "}
                            kg
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {delatePopup.open && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8 w-96">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Elimina prodotto</h2>
                <div className="mt-4">
                  <p className="text-gray-700 dark:text-gray-400">Sei sicuro di voler eliminare l'articolo?</p>
                </div>
                <div className="mt-4">
                  <button
                    onClick={() => setDelatePopup({ open: false, productId: {} })}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg"
                  >
                    Annulla
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteProduct(delatePopup.productId);
                    }}
                    className="bg-blue-500 ml-4 text-white py-2 px-4 rounded-lg"
                  >
                    Elimina
                  </button>
                </div>
              </div>
            </div>
          )}

          {addProductPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8 w-96">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Aggiungi prodotto</h2>
                <div className="mt-4">
                  <label htmlFor="nome" className="block text-sm text-gray-700 dark:text-gray-400">
                    Produttore
                  </label>
                  <select
                    id="nome"
                    name="nome"
                    value={formProduct.Der_CF}
                    onChange={(e) => {
                      setFormProduct({
                        Der_CF: e.target.value,
                        Der_id: "",
                        quantita: 0,
                        categoria: "",
                        blister: false,
                        nome: "",
                        massa_attiva: 0,
                        id: "",
                        tag: "",
                      });
                      getArticle(e.target.value);
                    }}
                    className="block w-full px-3 py-2 mt-1 text-sm dark:bg-gray-700 dark:text-white border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option disabled value="">
                      Seleziona un produttore
                    </option>
                    {producer.map((producer) => (
                      <option key={producer.CF} value={producer.CF}>
                        {producer.Nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4">
                  <label htmlFor="id" className="block text-sm text-gray-700 dark:text-gray-400">
                    Articolo
                  </label>
                  <select
                    id="id"
                    name="id"
                    value={formProduct.Der_id}
                    onChange={(e) => {
                      const selectedArticle = articles.find((article) => article.id === e.target.value);
                      if (selectedArticle) {
                        setFormProduct({
                          ...formProduct,
                          Der_id: e.target.value,
                          nome: selectedArticle.nome,
                          massa_attiva: selectedArticle.massa_attiva,
                          categoria: selectedArticle.App_nome,
                          blister: selectedArticle.blister,
                          id: selectedArticle.id,
                          tag: selectedArticle.tag,
                        });
                      }
                    }}
                    className="block w-full px-3 py-2 mt-1 text-sm dark:bg-gray-700 dark:text-white border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option disabled value="">
                      Seleziona un articolo
                    </option>
                    {articles.map((article) => (
                      <option key={article.id} value={article.id}>
                        {article.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4 mt-4 flex flex-row items-center gap-4">
                  {/* Quantità */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="quantita" className="block text-sm text-gray-700 dark:text-gray-400">
                      Quantità
                    </label>
                    <input
                      type="number"
                      id="quantita"
                      name="quantita"
                      value={formProduct.quantita}
                      onChange={(e) => setFormProduct({ ...formProduct, quantita: Number(e.target.value) })}
                      className="block w-full px-3 py-2 mt-1 text-sm dark:bg-gray-700 dark:text-white border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  {/* Categoria */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="categoria" className="block text-sm text-gray-700 dark:text-gray-400">
                      Situato in
                    </label>
                    <select
                      id="categoria"
                      name="categoria"
                      value={formProduct.categoria}
                      disabled={formProduct.categoria !== "5D"}
                      onChange={(e) => setFormProduct({ ...formProduct, categoria: e.target.value })}
                      className="block w-full px-3 py-2 mt-1 text-sm dark:bg-gray-700 dark:text-white border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option disabled value="">
                        Seleziona una categoria
                      </option>
                      {formProduct.categoria === "5C" && <option>5C</option>}
                      <option>5D</option>
                      <option>5D - MAGAZZINO</option>
                    </select>
                  </div>
                </div>
                <div className="mt-4">
                  <button onClick={() => setAddProductPopup(false)} className="bg-red-500 text-white py-2 px-4 rounded-lg">
                    Annulla
                  </button>
                  <button
                    onClick={() => {
                      handleAddProduct();
                    }}
                    className="bg-blue-500 ml-4 text-white py-2 px-4 rounded-lg"
                  >
                    Aggiungi
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
