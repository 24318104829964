export enum RoutesDef {
    HOME = '/',
    FAQ = '/faq',
    NORMATIVA = '/normativa',
    CONSIGLI = '/consigli',

    CONTROL = '/control',
    LOGIN = '/login',

    NOT_FOUND = '*',

    // Admin
    DASHBOARD = '/control/dashboard',
    APERTURE = '/control/aperture',
    STORICO = '/control/storico',
    REPORT = '/control/report',
    CARICO = '/control/carico',
    CATALOGO = '/control/catalogo',
    ORDINI = '/control/ordini',
    NEGOZIO = '/control/negozio',
    COMMESSI = '/control/commessi',
    RIMBORSI = '/control/rimborsi',

}
