import React, { useEffect, useState, useCallback } from "react";
import CustomSidebar from "../CustomSidebar.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../../AuthContext.tsx";

library.add(faBars, faTimes, faPenToSquare);
//CRDTMS01M29C573A
export default function Commessi() {
  const { idToken } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [addCommessoPopup, setAddCommessoPopup] = useState(false);
  const [updateCommessoPopup, setUpdateCommessoPopup] = useState<any>(false);
  const [formCommesso, setFormCommesso] = useState({
    Nome: "",
    Cognome: "",
    CF: "",
    Borsellino: "",
  });
  const [commessi, setCommessi] = useState([{ id: "", Nome: "", Cognome: "", CF: "", Borsellino: "" }]);

  const toggledAddCommessoPopup = () => {
    setAddCommessoPopup(!addCommessoPopup);
  };

  const getCommessi = useCallback(() => {
    axios
      .get("https://tommasoceredi.it/api/v1/getEmployee", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          const formattedCommessi = response.data.result.map((commesso) => ({
            id: commesso.CF,
            Nome: commesso.Nome,
            Cognome: commesso.Cognome,
            CF: commesso.CF,
            Borsellino: commesso.Borsellino,
          }));
          setCommessi(formattedCommessi);
        }
      })
      .catch((error) => {
        console.error("Errore nel recupero dei commessi:", error);
      });
  }, [idToken]);

  const handleAddCommesso = () => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addEmployee",
        {
          nome: formCommesso.Nome,
          cognome: formCommesso.Cognome,
          CF: formCommesso.CF.toUpperCase(),
          borsellino: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          console.log(response);
          toast.success("Commesso aggiunto con successo");
          toggledAddCommessoPopup();
          getCommessi();
        } else {
          console.error("Errore nell'aggiunta del commesso:", response.data.error);
          toast.error("Errore nell'aggiunta del commesso");
        }
      })
      .catch((error) => {
        console.error("Errore nell'aggiunta del commesso:", error);
        toast.error("Errore nell'aggiunta del commesso");
      });
  };

  const handleUpdateEmployee = (CF, Borsellino) => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/updateEmployee",
        {
          CF: CF,
          borsellino: Borsellino,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Borsellino aggiornato con successo");
          getCommessi();
          setUpdateCommessoPopup(false);
        } else {
          console.error("Errore nell'aggiornamento del borsellino:", response.data.error);
          toast.error("Errore nell'aggiornamento del borsellino");
        }
      })
      .catch((error) => {
        console.error("Errore nell'aggiornamento del borsellino:", error);
        toast.error("Errore nell'aggiornamento del borsellino");
      });
  };

  useEffect(() => {
    getCommessi();
  }, [getCommessi]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className="min-h-screen flex flex-col  " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="max-w-screen-xl w-full mx-auto p-4">
              <div className="flex items-center">
                <h1 className="text-white text-2xl font-bold p-4">Lista Commessi</h1>
                <button
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded ml-auto"
                  onClick={toggledAddCommessoPopup}
                >
                  Aggiungi Commesso
                </button>
              </div>
              <div className="bg-white shadow-md rounded my-6">
                <table className="min-w-full bg-white mb-8">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Nome</th>
                      <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Cognome</th>
                      <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">CF</th>
                      <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Borsellino</th>
                      <th className="text-center align-middle py-3 px-4 uppercase font-semibold text-sm">Azioni</th>
                    </tr>
                  </thead>
                  <tbody className="text-black">
                    {commessi.map((commesso) => (
                      <tr key={commesso.id} className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">{commesso.Nome}</td>
                        <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">{commesso.Cognome}</td>
                        <td className="text-center align-middle py-3 px-6 text-center">{commesso.CF}</td>
                        <td className="text-center align-middle py-3 px-6 text-center">{commesso.Borsellino}€</td>
                        <td className="text-center align-middle py-3 px-6 text-center">
                          <FontAwesomeIcon icon={faPenToSquare} onClick={() => setUpdateCommessoPopup(commesso)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {addCommessoPopup && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white w-1/3 p-4 rounded">
                  <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Aggiungi Commesso</h1>
                    <button onClick={toggledAddCommessoPopup}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Nome</label>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Nome"
                      value={formCommesso.Nome}
                      onChange={(e) => setFormCommesso({ ...formCommesso, Nome: e.target.value })}
                    />

                    <label className="block text-sm font-medium text-gray-700">Cognome</label>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                        "
                      placeholder="Cognome"
                      value={formCommesso.Cognome}
                      onChange={(e) => setFormCommesso({ ...formCommesso, Cognome: e.target.value })}
                    />

                    <label className="block text-sm font-medium text-gray-700">CF</label>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="CF"
                      value={formCommesso.CF}
                      onChange={(e) => setFormCommesso({ ...formCommesso, CF: e.target.value })}
                    />

                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mt-4"
                      onClick={handleAddCommesso}
                    >
                      Aggiungi
                    </button>
                  </div>
                </div>
              </div>
            )}
            {updateCommessoPopup && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white w-1/3 p-4 rounded">
                  <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Aggiorna Borsellino</h1>
                    <button onClick={() => setUpdateCommessoPopup(false)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Nome</label>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                        "
                      placeholder="Nome"
                      value={updateCommessoPopup.Nome + " " + updateCommessoPopup.Cognome}
                      disabled
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Borsellino</label>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                        "
                      placeholder="Borsellino"
                      value={updateCommessoPopup.Borsellino}
                      onChange={(e) => setUpdateCommessoPopup({ ...updateCommessoPopup, Borsellino: e.target.value })}
                    />
                    <button
                      className="bg-yellow-500 mr-5 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mt-4"
                      onClick={() => handleUpdateEmployee(updateCommessoPopup.CF, updateCommessoPopup.Borsellino)}
                    >
                      Aggiorna
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
                      onClick={() => setUpdateCommessoPopup(false)}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
