import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { faShieldHalved, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { faChampagneGlasses } from "@fortawesome/free-solid-svg-icons";

import Navbar from "../components/Navbar.tsx";
import Footer from "../components/Footer.tsx";
import axios from "axios";

library.add(
  faFacebookF,
  faFileLines,
  faArrowAltCircleDown,
  faInstagram,
  faPaw,
  faAward,
  faShieldHalved,
  faCalendarDays,
  faUserTie,
  faCertificate,
  faChampagneGlasses,
  faPhoneVolume
);

const date_logo = require("../static/img/date-logo.png");
const matrimonio = require("../static/img/matrimonio.jpg");

export default function Landing() {
  const [openingDb, setOpeningDb] = useState([]);

  const reviewsData = [
    {
      author: "Gabriele C.",
      title: "Riferimento nel settore",
      text: "Molto forniti, super preparati, gentilissimi mi hanno saputo consigliare proprio ciò che volevo. Una garanzia!",
    },
    {
      author: "Stefania A.",
      title: "Prodotti Imbattibili",
      text: "Storico negozietto di fuochi d'artificio, fontane luminose, petardi, stelline, girandole e chi più ne ha più ne metta.",
    },
    {
      author: "Andrea C.",
      title: "Brillare gli occhi",
      text: "Esperienza incredibile, qualità estrema. Se cercate i veri botti che fanno brillare gli occhi….sapere dove andare.",
    },
    {
      author: "Alberto C.",
      title: "Felicità",
      text: "Ho comprato dei Magnum, sono potentissimi.",
    },
    {
      author: "Manuel A.",
      title: "Staff Qualificato",
      text: "Staff molto disponibile con tantissimi prodotti, consigliato.",
    },
    {
      author: "Diego B.",
      title: "Assortimento",
      text: "Vasto assortimento di fuochi d'artificio",
    },
    {
      author: "Giovanni D.",
      title: "Consigliato",
      text: "Ottimo negozio, personale preparato e gentile.",
    },
    {
      author: "Marcello G.",
      title: "Fiducia",
      text: "Molto belli son rimasto molto contento dei vostri prodotti. Ci vediamo il prossimo anno.",
    },
    {
      author: "Davide C.",
      title: "Rifornimento",
      text: "Grande rifornimento.",
    },
  ];

  const getDaysToOpen = (dateStr: string) => {
    if (!dateStr) {
      return null;
    }
    const dateParts = dateStr.split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[0]);
    const openingDate = new Date(year, month - 1, day).getTime();
    const today = new Date().getTime();
    const diffTime = openingDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return null;
    }
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]) - 1; // Sottrai 1 perché i mesi sono indicizzati da 0 a 11
    const day = parseInt(dateParts[0]);
    const dateObject = new Date(year, month, day);

    // Array di nomi dei giorni della settimana e dei mesi
    const daysOfWeek = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    // Estrarre il giorno della settimana, il giorno del mese e il mese dalla data
    const dayOfWeek = daysOfWeek[dateObject.getDay()];
    const dayOfMonth = dateObject.getDate();
    const monthName = months[dateObject.getMonth()];

    // Costruire la stringa del risultato
    const formattedDate = `${dayOfWeek} ${dayOfMonth} ${monthName}`;

    return formattedDate;
  };

  const getOpeningDb = useCallback(async () => {
    try {
      const response = await axios.post("https://tommasoceredi.it/api/v1/getOpeningModena");
      const itDate = response.data.result.map((opening) => {
        // ogni apertura il campo data viene convertito in toLocaleDateString("it-IT")
        const date = new Date(opening.data);
        const formattedDate = date.toLocaleDateString("it-IT");
        return {
          id: formattedDate,
          data: formattedDate,
          oraInizio: opening.oraInizio,
          oraFine: opening.oraFine,
          oraInizioP: opening.oraInizioP,
          oraFineP: opening.oraFineP,
        };
      });
      console.log(itDate);
      const sortedData = itDate.sort((a, b) => {
        const dateA = new Date(a.data.replace(/\//g, "-"));
        const dateB = new Date(b.data.replace(/\//g, "-"));
        return dateA.getTime() - dateB.getTime();
      });
      console.log(sortedData);
      setOpeningDb(sortedData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getOpeningDb();
    };
    const initialize = async () => {
      await fetchData();
    };
    initialize();
  }, [getOpeningDb]);

  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <video autoPlay loop muted playsInline className="absolute top-0 w-full h-full object-cover">
            <source src="/IRazziGroupTMippodromo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <span id="blackOverlay" className="w-full h-full absolute inset-0 opacity-75 bg-black"></span>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="">
                  <h1 className="text-yellow-300 font-semibold text-5xl">I Razzi Store Modena</h1>
                  <p className="mt-4 text-lg text-gray-300">
                    Da oltre 30 anni al vostro fianco per rendere indimenticabili i vostri eventi.
                  </p>
                  <p className="text-lg text-gray-300">
                    Fuochi d'artificio di alta qualità e sicurezza certificata per ogni esigenza. Per qualsiasi informazione non
                    esitare a contattarci.
                  </p>
                  <div className="mt-6">
                    <div className="flex justify-center w-full">
                      <button
                        className="block lg:hidden bg-yellow-300 text-gray-800 active:bg-yellow-300 text-sm font-bold uppercase px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          window.location.href = "tel:+393343947875";
                        }}
                      >
                        CHIAMA&nbsp;
                        <FontAwesomeIcon icon={faPhoneVolume} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24 ">
          <div className="container mx-auto sm:p-4 lg:p-0"></div>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-2 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <FontAwesomeIcon icon={faAward} size="lg" />
                    </div>
                    <h6 className="text-xl font-semibold">Prodotti di Qualità</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Scegli tra una selezione di prodotti certificati per un’esperienza indimenticabile e senza preoccupazioni.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-8 w-full md:w-4/12 px-4 text-center lg:order-last sm:order-first">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <FontAwesomeIcon icon={faShieldHalved} size="lg" />
                    </div>
                    <h6 className="text-xl font-semibold">Sicurezza Certificata</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Ogni fuoco d’artificio che scegli è stato testato e certificato per offrirti il massimo della sicurezza e
                      delle prestazioni.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-12 w-full md:w-4/12 px-4 text-center lg:order-first sm:order-last">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <FontAwesomeIcon icon={faPaw} size="lg" />
                    </div>
                    <h6 className="text-xl font-semibold">Rispetto di Persone e Animali</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Vivi ogni scoppio come un’esperienza magica insieme ai tuoi cari, con la tranquillità di rispettare il
                      benessere degli animali intorno a te.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-14 lg:mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mb-8 md:mb-0">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-yellow-300">
                  <FontAwesomeIcon icon={faCalendarDays} size="2x" />
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">I nostri giorni di apertura:</h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Scopri i giorni in cui puoi venire a trovarci! Siamo aperti per offrirti la massima disponibilità e soddisfare
                  le tue esigenze.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  Stai organizzando un evento, un compleanno o un matrimonio? Contattaci per prenotare il tuo appuntamento:
                  insieme renderemo la tua festa indimenticabile e senza pensieri!
                </p>
                {window.innerWidth > 768 ? (
                  <span className="font-bold text-gray-800 mt-8">Scorri le date che vedi qui a destra</span>
                ) : (
                  <span className="font-bold text-gray-800 mt-8">Scorri le date che vedi qui sotto</span>
                )}
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <Slider
                  dots={false}
                  infinite={false}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  centerMode={true}
                  // voglio vedere solo la carta attiva
                  centerPadding="0"
                  className="w-full lg:w-9/12 px-4"
                >
                  {openingDb.map((opening, index) => (
                    <div
                      //@ts-ignore
                      key={opening.id}
                      className="w-full max-w-xs border border-gray-200 rounded-lg shadow bg-gray-800 border-gray-700"
                    >
                      <div className="flex flex-col items-center pb-5">
                        <div className="mb-3 mt-3 flex justify-center px-2 pt-2">
                          <span className="text-1xl font-bold text-white">
                            {
                              //@ts-ignore
                              getDaysToOpen(opening.data) > 1 ? (
                                "Apertura tra " +
                                //@ts-ignore
                                getDaysToOpen(opening.data) +
                                " giorni"
                              ) : //@ts-ignore
                              getDaysToOpen(opening.data) === 1 ? (
                                "Apertura domani"
                              ) : //@ts-ignore
                              getDaysToOpen(opening.data) === 0 ? (
                                <div className="flex items-center justify-center w-32 h-8 bg-green-600 rounded-full shadow-lg">
                                  Aperto oggi
                                </div>
                              ) : (
                                "Apertura passata"
                              )
                            }
                          </span>
                        </div>
                        <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={date_logo} alt="Date-logo" />
                        <h5 className="mb-1 text-xl font-medium text-white">
                          {formatDate(
                            //@ts-ignore
                            opening.data
                          )}
                        </h5>
                        <span className="text-sm text-gray-400">
                          {
                            //@ts-ignore
                            opening.oraInizio !== "" ||
                            //@ts-ignore
                            opening.oraFine !== ""
                              ? "Mattina: " +
                                //@ts-ignore
                                opening.oraInizio +
                                " - " +
                                //@ts-ignore
                                opening.oraFine
                              : "Mattina: chiuso"
                          }
                        </span>
                        <span className="text-sm text-gray-400">
                          {
                            //@ts-ignore
                            opening.oraInizioP !== "" ||
                            //@ts-ignore
                            opening.oraFineP !== ""
                              ? "Pomeriggio: " +
                                //@ts-ignore
                                opening.oraInizioP +
                                " - " +
                                //@ts-ignore
                                opening.oraFineP
                              : "Pomeriggio: chiuso"
                          }
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className="w-full max-w-xs border border-gray-200 rounded-lg shadow bg-gray-800 border-gray-700">
                    <div className="flex flex-col items-center pb-5">
                      <div className="mb-3 mt-3 flex justify-center px-2 pt-2">
                        <span className="text-1xl font-bold text-white">Prossimamente</span>
                      </div>
                      <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={date_logo} alt="Date-logo" />
                      <h5 className="mb-1 text-xl font-medium text-white">Data in arrivo</h5>
                      <span className="text-sm text-gray-400">per urgenze o informazioni</span>
                      <span className="text-sm text-gray-400">contattaci</span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20 bg-white">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img alt="..." className="max-w-full rounded-lg shadow-lg" src={matrimonio} />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12 mt-10 lg:mt-0">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-yellow-300">
                    <FontAwesomeIcon icon={faChampagneGlasses} size="2xl" />
                  </div>
                  <h3 className="text-3xl font-semibold">Il Tuo Evento, Il Tuo Spettacolo</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Affidati a professionisti del settore per organizzare il tuo spettacolo pirotecnico. Sapremo consigliarti e
                    guidarti nella scelta migliore per la tua festa.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1.5 px-2 uppercase rounded-full text-gray-600 bg-yellow-300 mr-3">
                            <FontAwesomeIcon icon={faUserTie} size="lg" />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Professionalità e competenza</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1.5 px-2 uppercase rounded-full text-gray-600 bg-yellow-300 mr-3">
                            <FontAwesomeIcon icon={faAward} size="lg" />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Qualità e sicurezza</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1.5 px-2 uppercase rounded-full text-gray-600 bg-yellow-300 mr-3">
                            <FontAwesomeIcon icon={faCertificate} size="lg" />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Successo garantito</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="text-gray-900 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">Il Vostro Feedback Conta</h2>
                <p className="text-lg leading-relaxed mt-4 text-gray-500">
                  Ci impegniamo a garantire la massima soddisfazione dei nostri clienti. Ecco cosa dicono di noi.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-8 justify-center mb-20 lg:mb-0">
              <Slider
                dots={false}
                infinite={true}
                speed={1000}
                slidesToShow={window.innerWidth > 768 ? 3 : 1}
                slidesToScroll={1}
                centerMode={false}
                autoplay={true}
                arrows={false}
                className="w-full lg:w-9/12 px-4"
              >
                {reviewsData.map((review, index) => (
                  <div key={index} className="w-full lg:w-3/12 px-4 text-center">
                    <h5 className="text-xl mt-5 font-semibold text-white">{review.author}</h5>
                    <p className="mt-2 mb-4 text-gray-500">{review.text}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">Ti aspettiamo in negozio</h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">Controlla i giorni di apertura</p>
                    <iframe
                      title="IRazziStoreModenaMap"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2838.3632470669236!2d10.933006576195329!3d44.65092967107235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fef3d1c87f219%3A0xf7fe64bb981eac4!2sI%20Razzi%20Store%20Modena!5e0!3m2!1sit!2snl!4v1709723716967!5m2!1sit!2snl"
                      width={"100%"}
                      height={450}
                      style={{ border: 0 }}
                      allowFullScreen={true}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
