import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
  } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDViFTsUmNyj_tu0Alb_ZHgDrbmKVn3TU8",
  authDomain: "irazzistoremodena.firebaseapp.com",
  projectId: "irazzistoremodena",
  storageBucket: "irazzistoremodena.firebasestorage.app",
  messagingSenderId: "258635917",
  appId: "1:258635917:web:bca27e925bdbb0b72e0ac4",
  measurementId: "G-LZQ6W89EEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const signIn = signInWithEmailAndPassword;
export const signUp = createUserWithEmailAndPassword;
export const logout = signOut;