import React, { useEffect, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../AuthContext.tsx";
import axios from "axios";

library.add(faBars, faCaretDown);

export default function NavAdmin({ broken, toggled, setToggled }) {
  const { idToken, setSelectedShop, selectedShop } = useAuth();
  const [shops, setShops] = useState<Array<{ id: string; Nome: string; Indirizzo }>>([]);
  const [showShopList, setShowShopList] = useState(false);

  const getShops = useCallback(async () => {
    try {
      const response = await axios.get("https://tommasoceredi.it/api/v1/getShop", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const formattedShops = response.data.result.map((shop) => ({
        id: shop.Nome,
        Nome: shop.Nome,
        Indirizzo: shop.Indirizzo,
      }));
      setShops(formattedShops); // Sovrascrive lo stato con i nuovi dati
      if (selectedShop === null) {
        console.log("selectedShop è null");
        setSelectedShop(formattedShops[0]);
      }
    } catch (error) {
      console.error("Errore nel recupero dei negozi:", error);
    }
  }, [idToken, selectedShop, setSelectedShop]);

  const handleShopChange = (shop) => {
    setSelectedShop(shop);
  };

  const status = useCallback(async () => {
    try {
      const response = await axios.get("https://tommasoceredi.it/api/v1/status", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
    } catch (error) {
      console.error("Errore nel recupero dello status:", error);
    }
  }, [idToken]);

  useEffect(() => {
    getShops();
    status();
  }, [getShops, status]);

  return (
    <div>
      <nav className="border-gray-200 bg-slate-900 h-12">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
          <div className="flex items-center space-x-3 ">
            {broken && (
              <button
                type="button"
                className="text-white font-medium text-sm p-2.5 text-center inline-flex items-center me-2"
                onClick={() => setToggled(!toggled)}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            )}
            <div className="flex items-center space-x-2">
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">{selectedShop?.Nome}</span>
              <FontAwesomeIcon
                icon={faCaretDown}
                className="self-center text-gray-500 text-white cursor-pointer"
                onClick={() => setShowShopList(!showShopList)}
              />
            </div>
            {showShopList && (
              <div className="absolute bg-slate-900 border border-gray-200 border-gray-700 mt-24 p-2 rounded shadow-lg w-52 z-50">
                {shops.map((shop) => (
                  <div
                    key={shop.id}
                    className="cursor-pointer hover:bg-gray-100 hover:bg-gray-700 p-2 rounded"
                    onClick={() => {
                      handleShopChange(shop);
                      setShowShopList(false);
                    }}
                  >
                    <p className="text-md text-white">{shop.Nome}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
