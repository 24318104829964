import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext.tsx";
import { RoutesDef } from "./components/RoutesDef.tsx";

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { currentUser } = useAuth();

  // Se l'utente non è autenticato, reindirizza alla pagina di login
  if (!currentUser) {
    console.log("Utente non autenticato");
    return <Navigate to={RoutesDef.LOGIN} replace />;
  }

  // Altrimenti, mostra il componente figlio
  return children;
};

export default ProtectedRoute;
