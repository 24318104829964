import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebase";
import { User, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";

interface AuthContextType {
  currentUser: User | null;
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  idToken: string | null;
  selectedShop: {
    id: string;
    Nome: string;
    Indirizzo: string;
  } | null;
  setSelectedShop: React.Dispatch<
    React.SetStateAction<{
      id: string;
      Nome: string;
      Indirizzo: string;
    } | null>
  >;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve essere usato all'interno di un AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [selectedShop, setSelectedShop] = useState<{
    id: string;
    Nome: string;
    Indirizzo: string;
  } | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        user.getIdToken().then((token) => setIdToken(token)).catch(() => setIdToken(null));
      } else {
        setIdToken(null);
      }
      setLoading(false);
    });

    return unsubscribe; // Cleanup listener
  }, []);

  const login = async (email: string, password: string): Promise<User> => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const idToekn = await auth.currentUser?.getIdToken();
    setIdToken(idToekn ? idToekn : null);
    return userCredential.user;
  };

  const logout = () => {
    setCurrentUser(null);
    setIdToken(null);
    return signOut(auth);
  };

  const value = { currentUser, login, logout, idToken, selectedShop, setSelectedShop };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
