import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { RoutesDef } from "../../../components/RoutesDef.tsx";
import CustomSidebar from "../CustomSidebar.tsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCaretDown, faCaretUp, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faSquareCaretDown, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import { useAuth } from "../../../AuthContext.tsx";
import toast, { Toaster } from "react-hot-toast";

library.add(faBars, faRightFromBracket, faSquareCaretDown, faSquareCaretUp, faCaretDown, faCaretUp, faEdit);

export default function Catalogo() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [selectedProducer, setSelectedProducer] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isMenuProduttoreOpen, setIsMenuProduttoreOpen] = useState(false);
  const [isMenuCategoriaOpen, setIsMenuCategoriaOpen] = useState(false);
  const [isEditProductPopup, setIsEditProductPopup] = useState<boolean>(false);
  const [selectedProductEdit, setSelectedProductEdit] = useState<any>({});
  const [isInfoOpen, setisInfoOpen] = useState<{ [key: number]: boolean }>({});
  const { idToken, selectedShop } = useAuth();
  const [producer, setProducer] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [filterArticles, setFilterArticles] = useState<any[]>([]);
  const [addPorducerPopup, setAddProducerPopup] = useState(false);
  const [addProductPopup, setAddProductPopup] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [formProducer, setFormProducer] = useState({
    Nome: "",
    Sconto: "",
    CF: "",
  });
  const [formProduct, setFormProduct] = useState({
    Nome: "",
    CF: "",
    anno_rif: new Date().getFullYear(),
    costo: "",
    massa_attiva: "",
    id: "",
    App_nome: "",
    extra_sconto: 0,
    link_video: "",
    tag: "",
    blister: false,
  });

  const resetFormProduct = () => {
    setFormProduct({
      Nome: "",
      CF: "",
      anno_rif: new Date().getFullYear(),
      costo: "",
      massa_attiva: "",
      id: "",
      App_nome: "",
      extra_sconto: 0,
      link_video: "",
      tag: "",
      blister: false,
    });
  };

  const toggleMenuProduttore = () => {
    console.log("selectedProducer", selectedProducer);
    setIsMenuProduttoreOpen(!isMenuProduttoreOpen);
  };

  const toggleMenuCategoria = () => {
    console.log("selectedCategory", selectedCategory);
    setIsMenuCategoriaOpen(!isMenuCategoriaOpen);
  };

  const toggleInfo = (index: number) => {
    // Invertiamo il valore booleano per il prodotto all'indice specificato
    setisInfoOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggla lo stato per l'elemento indicato
    }));
  };

  const handleMenuProduttoreItemClick = (producer) => {
    getArticle(producer.CF); // Carica gli articoli del produttore selezionato
    setSelectedProducer(producer); // Imposta la tipologia selezionata
    setIsMenuProduttoreOpen(false); // Chiudi il menu
  };

  const handleMenuCategoriaItemClick = (categoria) => {
    console.log(categoria);
    setSelectedCategory(categoria); // Imposta la tipologia selezionata
    setIsMenuCategoriaOpen(false); // Chiudi il menu
  };

  useEffect(() => {
    // Se non è stata selezionata una categoria, mostriamo tutti gli articoli
    if (!selectedCategory) {
      setFilterArticles(articles);
    } else {
      // Altrimenti, filtriamo gli articoli per categoria
      setFilterArticles(articles.filter((articolo) => articolo.App_nome === selectedCategory));
    }
  }, [articles, selectedCategory]);

  const getArticle = useCallback(
    async (CF) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/getUniqueArticleByProducer",
          {
            CF: CF,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (!response.data.status) {
            toast.error("Errore nel caricamento degli articoli");
            return;
          }
          console.log(response.data.result);
          setArticles(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [idToken]
  );

  const handleUpdateProduct = useCallback(async () => {
    // controllo se un prodotto con lo stesso anno esiste già
    const check = articles.find(
      (article) => article.anno_rif === selectedProductEdit.anno_rif && article.id === selectedProductEdit.id
    );
    console.log("blister", selectedProductEdit.blister);
    console.log("check", check);
    if (check) {
      toast.error("Esiste già un prodotto con lo stesso anno");
      return;
    }
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addArticle",
        {
          nome: selectedProductEdit.nome,
          CF: selectedProductEdit.CF,
          anno_rif: selectedProductEdit.anno_rif,
          costo: selectedProductEdit.costo,
          massa_attiva: selectedProductEdit.massa_attiva,
          id: selectedProductEdit.id,
          App_nome: selectedProductEdit.App_nome,
          link_video: selectedProductEdit.link_video,
          extra_sconto: selectedProductEdit.extra_sconto,
          tag: selectedProductEdit.tag,
          blister: selectedProductEdit.blister,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setIsEditProductPopup(false);
          setSelectedProductEdit({});
          toast.success("Prodotto modificato con successo");
          getArticle(selectedProducer.CF);
        } else {
          toast.error("Errore nella modifica del prodotto");
          console.error(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken, selectedProductEdit, selectedProducer.CF, getArticle, articles]);

  const handleAddArticle = useCallback(async () => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addArticle",
        {
          nome: formProduct.Nome,
          CF: formProduct.CF,
          anno_rif: formProduct.anno_rif,
          costo: formProduct.costo,
          massa_attiva: formProduct.massa_attiva,
          id: formProduct.id,
          App_nome: formProduct.App_nome,
          link_video: formProduct.link_video,
          extra_sconto: formProduct.extra_sconto,
          tag: formProduct.tag,
          blister: formProduct.blister,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setAddProductPopup(false);
          resetFormProduct();
          toast.success("Prodotto aggiunto con successo");
          getArticle(selectedProducer.CF);
        } else {
          toast.error("Errore nell'aggiunta del prodotto");
          console.error(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken, formProduct, selectedProducer.CF, getArticle]);

  const getCategorie = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getCategory", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (!response.data.status) {
          toast.error("Errore nel caricamento delle categorie");
          return;
        }
        setCategories(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken]);

  const getProducer = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getProducer", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (!response.data.status) {
          toast.error("Errore nel caricamento dei produttori");
          return;
        }
        setProducer(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken]);

  const handleAddProducer = useCallback(async () => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addProducer",
        {
          nome: formProducer.Nome,
          sconto: formProducer.Sconto,
          CF: formProducer.CF,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          getProducer();
          setAddProducerPopup(false);
          setFormProducer({ Nome: "", Sconto: "", CF: "" });
          toast.success("Produttore aggiunto con successo");
        } else {
          toast.error("Errore nell'aggiunta del produttore");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idToken, getProducer, formProducer]);

  useEffect(() => {
    if (producer.length === 0) {
      console.log("Carico i produttori");
      getProducer();
    }
    console.log("Produttori", producer);
  }, [getProducer, producer]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className=" flex flex-col min-h-screen " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="flex justify-center">
              <div className="max-w-screen-xl w-full">
                <caption className="m-5 p-5 flex items-center rounded-lg justify-between text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  <div>
                    Lista dei prodotti
                    <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                      Qui sono visualizzati tutti i prodotti acquistabili dai vari rivenditori. Seleziona un produttore per
                      visualizzare i suoi prodotti.
                    </p>
                  </div>
                  {/* <button
                    className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                    onClick={() => setAddProducerPopup(true)}
                  >
                    Aggiungi Produttore
                  </button> */}
                </caption>

                <div className="relative shadow-md sm:rounded-lg mt-10">
                  <div className="flex items-center justify-between flex-column flex-wrap md:flex-row md:space-y-0 bg-white dark:bg-gray-900">
                    {/* Contenitore per i due dropdown */}
                    <div className="flex items-center space-x-2">
                      {/* Seleziona produttore */}
                      <div className="relative">
                        <button
                          id="menu-button"
                          data-dropdown-toggle="dropdownAction"
                          className="m-4 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                          type="button"
                          onClick={toggleMenuProduttore}
                        >
                          <span className="sr-only">Action button</span>
                          {selectedProducer.Nome ? selectedProducer.Nome : "Seleziona produttore"}
                          <FontAwesomeIcon icon={isMenuProduttoreOpen ? faCaretUp : faCaretDown} className="ml-2" />
                        </button>

                        <div
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          className={`z-50 ${
                            isMenuProduttoreOpen ? "block" : "hidden"
                          } mt-2 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                        >
                          <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                            {producer.length > 0 &&
                              producer.map((produttore, index) => (
                                <li key={index}>
                                  <div
                                    onClick={() => handleMenuProduttoreItemClick(produttore)}
                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                  >
                                    {produttore.Nome}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      {/* Seleziona categoria */}
                      <div className="relative">
                        <button
                          id="menu-button"
                          data-dropdown-toggle="dropdownAction"
                          className="m-4 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                          type="button"
                          onClick={toggleMenuCategoria}
                        >
                          <span className="sr-only">Action button</span>
                          {selectedCategory ? selectedCategory : "Seleziona categoria"}
                          <FontAwesomeIcon icon={isMenuCategoriaOpen ? faCaretUp : faCaretDown} className="ml-2" />
                        </button>
                        <div
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          className={`z-50 ${
                            isMenuCategoriaOpen ? "block" : "hidden"
                          } mt-2 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                        >
                          <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                            <li>
                              <div
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                onClick={() => handleMenuCategoriaItemClick(null)}
                              >
                                Tutte
                              </div>
                              <div
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                onClick={() => handleMenuCategoriaItemClick("5D")}
                              >
                                5D
                              </div>
                            </li>
                            <li>
                              <div
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                onClick={() => handleMenuCategoriaItemClick("5C")}
                              >
                                5C
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          setAddProductPopup(true);
                          getCategorie();
                        }}
                        className="px-4 m-5 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                      >
                        Aggiungi Prodotto
                      </button>
                    </div>
                  </div>

                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Nome
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <div className="flex items-center">Codice</div>
                        </th>
                        <th scope="col" className="px-6 py-3 hidden md:table-cell">
                          <div className="flex items-center">Massa attiva</div>
                        </th>
                        <th scope="col" className="px-6 py-3 hidden md:table-cell">
                          <div className="flex items-center">Blister</div>
                        </th>
                        <th scope="col" className="px-6 py-3 hidden md:table-cell">
                          <div className="flex items-center">Categoria</div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <span className="sr-only">Show</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterArticles.map((prodotto, index) => (
                        <React.Fragment key={index}>
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {prodotto?.nome}
                            </td>
                            <td className="px-6 py-4">{prodotto?.id}</td>
                            <td className="px-6 py-4 hidden md:table-cell">{prodotto?.massa_attiva}</td>
                            <td className="px-6 py-4 hidden md:table-cell">{prodotto?.blister === "1" ? "Si" : "No"}</td>
                            <td className="px-6 py-4 hidden md:table-cell">{prodotto.App_nome}</td>

                            <td className="px-6 py-4 text-right">
                              <FontAwesomeIcon
                                onClick={() => toggleInfo(index)}
                                className="cursor-pointer"
                                style={{ userSelect: "none" }} //non seleziona il testo
                                icon={isInfoOpen[index] ? faSquareCaretUp : faSquareCaretDown}
                              />
                            </td>
                          </tr>
                          {isInfoOpen[index] && (
                            <tr>
                              <td colSpan={4} className="px-6 py-4">
                                <div className="flex justify-between items-center">
                                  {/* Parte sinistra con i dettagli */}
                                  <div className="flex flex-col">
                                    <p>
                                      <strong>{prodotto?.produttore ? "Produttore: " : ""}</strong> {prodotto?.produttore}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.costo ? "Costo di acquisto: " : ""}</strong> {prodotto?.costo}
                                      {"€ -"}
                                      {
                                        producer.find((p) => p.CF === prodotto.CF)?.[
                                          prodotto.App_nome === "5D" ? "ScontoLIBERA" : "ScontoVC"
                                        ]
                                      }
                                      % ={" "}
                                      {(
                                        prodotto?.costo -
                                        (prodotto?.costo *
                                          producer.find((p) => p.CF === prodotto.CF)?.[
                                            prodotto.App_nome === "5D" ? "ScontoLIBERA" : "ScontoVC"
                                          ]) /
                                          100
                                      ).toFixed(2)}
                                      €
                                      {prodotto.extra_sconto !== 0
                                        ? ` -> extra ${prodotto.extra_sconto}% = ${(
                                            prodotto.costo -
                                            (prodotto.costo *
                                              (producer.find((p) => p.CF === prodotto.CF)?.[
                                                prodotto.App_nome === "5D" ? "ScontoLIBERA" : "ScontoVC"
                                              ] +
                                                prodotto.extra_sconto)) /
                                              100
                                          ).toFixed(2)}€`
                                        : ""}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.massa_attiva ? "Massa attiva: " : ""}</strong> {prodotto?.massa_attiva}g {prodotto?.blister ? ` -> Blister: ${prodotto?.massa_attiva/2}g` : ""}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.anno_rif ? "Anno riferimento: " : ""}</strong> {prodotto?.anno_rif}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.CF ? "Produttore: " : ""}</strong>{" "}
                                      {producer.find((p) => p.CF === prodotto.CF)?.Nome}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.link_video ? "Video prodotto: " : ""}</strong> <Link to={prodotto?.link_video} className="text-blue-500" target="_blank">
                                        Link video
                                      </Link>

                                    </p>
                                    <p>
                                      <strong>{prodotto?.tag ? "Tag: " : ""}</strong> {prodotto?.tag}
                                    </p>
                                    <p>
                                      <strong>{prodotto?.blister ? "Blisterato: " : ""}</strong> {prodotto?.blister ? "Si" : "No"}
                                    </p>
                                  </div>
                                  <button
                                    onClick={() => {
                                      setIsEditProductPopup(true);
                                      setSelectedProductEdit(prodotto);
                                    }}
                                    className="text-gray-500 hover:text-blue-500"
                                  >
                                    <FontAwesomeIcon icon={faEdit} size="lg" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {addPorducerPopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-8 w-96">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Aggiungi Produttore</h2>

                <div className="mb-4">
                  <label htmlFor="nome" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Nome
                  </label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    value={formProducer.Nome}
                    onChange={(e) => setFormProducer({ ...formProducer, Nome: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="sconto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Sconto
                  </label>
                  <input
                    type="text"
                    id="sconto"
                    name="sconto"
                    value={formProducer.Sconto}
                    onChange={(e) => setFormProducer({ ...formProducer, Sconto: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="CF" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Codice Fiscale
                  </label>
                  <input
                    type="text"
                    id="CF"
                    name="CF"
                    value={formProducer.CF}
                    onChange={(e) => setFormProducer({ ...formProducer, CF: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                    onClick={handleAddProducer}
                  >
                    Aggiungi
                  </button>
                  <button
                    onClick={() => {
                      setAddProducerPopup(false);
                      setFormProducer({ Nome: "", Sconto: "", CF: "" });
                    }}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800 ml-4"
                  >
                    Annulla
                  </button>
                </div>
              </div>
            </div>
          )}

          {addProductPopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-8 w-96">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Aggiungi Prodotto</h2>

                <div className="mb-4">
                  <label htmlFor="nome_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Nome del prodotto
                  </label>
                  <input
                    type="text"
                    id="nome_prodotto"
                    name="nome_prodotto"
                    value={formProduct.Nome}
                    onChange={(e) => setFormProduct({ ...formProduct, Nome: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>

                <div className="mb-4 flex flex-row items-center gap-4">
                  {/* Codice prodotto */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="codice_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Codice del prodotto
                    </label>
                    <input
                      type="text"
                      id="codice_prodotto"
                      name="codice_prodotto"
                      value={formProduct.id}
                      onChange={(e) => setFormProduct({ ...formProduct, id: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  {/* Tag */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="tag_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Seleziona tag
                    </label>
                    <input
                      type="text"
                      id="tag_prodotto"
                      name="tag_prodotto"
                      list="tag_prodotto-suggestions"
                      value={formProduct.tag}
                      onChange={(e) => setFormProduct({ ...formProduct, tag: e.target.value })}
                      required
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <datalist id="tag_prodotto-suggestions">
                      <option value="Batteria:" />
                      <option value="Fontanta:" />
                      <option value="Petardo:" />
                      <option value="Fumogeno:" />
                      <option value="Candele:" />
                      <option value="Razzi:" />
                      <option value="Mortai:" />
                      <option value="Flambe:" />
                      <option value="Libera vendita:" />
                    </datalist>
                  </div>
                </div>

                <div className="mb-4 flex flex-row items-center gap-4">
                  {/* Anno rif */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="anno_riferimento" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Anno di riferimento
                    </label>
                    <select
                      id="anno_riferimento"
                      name="anno_riferimento"
                      value={formProduct.anno_rif ?? new Date().getFullYear()}
                      onChange={(e) => setFormProduct({ ...formProduct, anno_rif: Number(e.target.value) })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Massa attiva */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="massa_attiva" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Massa attiva g
                    </label>
                    <input
                      type="number"
                      id="massa_attiva"
                      name="massa_attiva"
                      value={formProduct.massa_attiva}
                      onChange={(e) => setFormProduct({ ...formProduct, massa_attiva: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>

                <div className="mb-4 flex flex-row items-center gap-4">
                  {/* Costo */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="costo" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Costo prodotto €
                    </label>
                    <input
                      type="number"
                      id="costo"
                      name="costo"
                      value={formProduct.costo}
                      onChange={(e) => setFormProduct({ ...formProduct, costo: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                  {/* Extra sconto */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="extra_sconto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Extra sconto %
                    </label>
                    <input
                      type="number"
                      id="extra_sconto"
                      name="extra_sconto"
                      value={formProduct.extra_sconto ?? 0}
                      onChange={(e) => setFormProduct({ ...formProduct, extra_sconto: Number(e.target.value) })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="link_video" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Link video
                  </label>
                  <input
                    type="text"
                    id="link_video"
                    name="link_video"
                    value={formProduct.link_video}
                    onChange={(e) => setFormProduct({ ...formProduct, link_video: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
                <div className="mb-4 flex flex-row items-center gap-4">
                  {/* Categoria */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="categoria" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Categoria
                    </label>
                    <select
                      id="categoria"
                      name="categoria"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      onChange={(e) => setFormProduct({ ...formProduct, App_nome: e.target.value })}
                      value={formProduct.App_nome}
                    >
                      <option value="" disabled>
                        Seleziona categoria
                      </option>
                      {categories
                      .filter((categoria) => categoria.nome !== "5D - MAGAZZINO")
                      .map((categoria) => (
                        <option key={categoria.id} value={categoria.id}>
                          {categoria.nome}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Produttore */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="produttore" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Produttore
                    </label>
                    <select
                      id="produttore"
                      name="produttore"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      onChange={(e) => setFormProduct({ ...formProduct, CF: e.target.value })}
                      value={formProduct.CF}
                    >
                      <option value="" disabled>
                        Seleziona produttore
                      </option>
                      {producer.map((produttore) => (
                        <option key={produttore.id} value={produttore.CF}>
                          {produttore.Nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="blisterato" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                    Blisterato
                  </label>
                  <div
                    className={`relative inline-flex items-center cursor-pointer`}
                    onClick={() => setFormProduct((prev) => ({ ...prev, blister: !prev.blister }))}
                  >
                    <div
                      className={`block w-10 h-6 rounded-full transition-colors ${
                        formProduct.blister ? "bg-green-600" : "bg-gray-300 dark:bg-gray-600"
                      }`}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition ${
                        formProduct.blister ? "translate-x-4" : ""
                      }`}
                    ></div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                    onClick={() => {
                      console.log(formProduct);
                      handleAddArticle();
                    }}
                  >
                    Aggiungi
                  </button>
                  <button
                    onClick={() => {
                      setAddProductPopup(false);
                      resetFormProduct();
                    }}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800 ml-4"
                  >
                    Annulla
                  </button>
                </div>
              </div>
            </div>
          )}
          {/*Pupup per modificare i dati dell'articolo */}
          {isEditProductPopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-8 w-96">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Modifica Prodotto</h2>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                  ATTENZIONE: modificando un prodotto si andrà a creare una nuova versione dello stesso. La versione precedente
                  rimarrà comunque per i riferimenti degli ordini passati.
                </p>

                <div className="mb-4">
                  <label htmlFor="nome_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Nome del prodotto
                  </label>
                  <input
                    type="text"
                    id="nome_prodotto"
                    name="nome_prodotto"
                    value={selectedProductEdit.nome + " - " + producer.find((p) => p.CF === selectedProductEdit.CF)?.Nome}
                    disabled
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>

                <div className="mb-4 flex flex-row items-center gap-4">
                  {/* Codice prodotto */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="codice_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Codice del prodotto
                    </label>
                    <input
                      type="text"
                      id="codice_prodotto"
                      name="codice_prodotto"
                      value={selectedProductEdit.id}
                      disabled
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  {/* Tag */}
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="tag_prodotto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Seleziona tag
                    </label>
                    <input
                      type="text"
                      id="tag_prodotto"
                      name="tag_prodotto"
                      list="tag_prodotto-suggestions"
                      value={selectedProductEdit.tag}
                      disabled
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-row items-center gap-4">
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="anno_riferimento" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Anno di riferimento
                    </label>
                    <select
                      id="anno_riferimento"
                      name="anno_riferimento"
                      value={selectedProductEdit.anno_rif}
                      onChange={(e) => setSelectedProductEdit({ ...selectedProductEdit, anno_rif: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="massa_attiva" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Massa attiva g
                    </label>
                    <input
                      type="number"
                      id="massa_attiva"
                      name="massa_attiva"
                      value={selectedProductEdit.massa_attiva}
                      onChange={(e) => setSelectedProductEdit({ ...selectedProductEdit, massa_attiva: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-row items-center gap-4">
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="costo" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Costo prodotto €
                    </label>
                    <input
                      type="number"
                      id="costo"
                      name="costo"
                      value={selectedProductEdit.costo}
                      onChange={(e) => setSelectedProductEdit({ ...selectedProductEdit, costo: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                  <div className="flex flex-col w-1/2">
                    <label htmlFor="extra_sconto" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Extra sconto %
                    </label>
                    <input
                      type="number"
                      id="extra_sconto"
                      name="extra_sconto"
                      value={selectedProductEdit.extra_sconto}
                      onChange={(e) => setSelectedProductEdit({ ...selectedProductEdit, extra_sconto: e.target.value })}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                    onClick={() => {
                      setIsEditProductPopup(false);
                      setSelectedProductEdit({});
                    }}
                  >
                    Chiudi
                  </button>
                  <button
                    onClick={() => {
                      handleUpdateProduct();
                    }}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800 ml-4"
                  >
                    Aggiorna
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
