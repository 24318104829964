import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RoutesDef } from "./components/RoutesDef.tsx";
import ReactDOM from "react-dom/client";
import "./static/css/index.css";
import { ToastProvider } from "tw-noti";

import { AuthProvider } from "./AuthContext.tsx";
import ProtectedRoute from "./ProtectedRoute.tsx";

// Sections
import Landing from "./sections/Landing.tsx";
import Faq from "./sections/Faq.tsx";
import Normativa from "./sections/Normativa.tsx";

// Controllo
import Rimborsi from "./sections/admin/controllo/Rimborsi.tsx";
import Opening from "./sections/admin/controllo/Opening.tsx";
import Dashboard from "./sections/admin/controllo/Dashboard.tsx";
import Negozio from "./sections/admin/controllo/Negozio.tsx";
import Storico from "./sections/admin/controllo/Storico.tsx";
import Commessi from "./sections/admin/controllo/Commessi.tsx";

// Magazzino
import Login from "./sections/Login.tsx";
import Carico from "./sections/admin/magazzino/Carico.tsx";
import Catalogo from "./sections/admin/magazzino/Catalogo.tsx";
import Ordini from "./sections/admin/magazzino/Ordini.tsx";

const BrowserRouter = createBrowserRouter([
  { path: RoutesDef.LOGIN, element: <Login /> },
  { path: RoutesDef.HOME, element: <Landing /> },
  { path: RoutesDef.FAQ, element: <Faq /> },
  { path: RoutesDef.NORMATIVA, element: <Normativa /> },
  {
    path: RoutesDef.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.RIMBORSI,
    element: (
      <ProtectedRoute>
        <Rimborsi />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.APERTURE,
    element: (
      <ProtectedRoute>
        <Opening />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.CARICO,
    element: (
      <ProtectedRoute>
        <Carico />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.STORICO,
    element: (
      <ProtectedRoute>
        <Storico />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.COMMESSI,
    element: (
      <ProtectedRoute>
        <Commessi />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.CATALOGO,
    element: (
      <ProtectedRoute>
        <Catalogo />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.ORDINI,
    element: (
      <ProtectedRoute>
        <Ordini />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesDef.NEGOZIO,
    element: (
      <ProtectedRoute>
        <Negozio />
      </ProtectedRoute>
    ),
  },
  { path: RoutesDef.NOT_FOUND, element: <Landing /> },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <ToastProvider
        maxToasts={3}
        timeout={2000}
        reverseStackOrder={true}
        containerClasses="top-12 right-12 space-y-2"
        messageClasses="dark:text-white font-extrabold"
      >
        <RouterProvider router={BrowserRouter} />
      </ToastProvider>
    </AuthProvider>
  </React.StrictMode>
);
