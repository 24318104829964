import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import NavAdmin from "../NavAdmin.tsx";
import { it } from "date-fns/locale/it";
import SimpleFooter from "../../../components/SimpleFooter.tsx";
import { RoutesDef } from "../../../components/RoutesDef.tsx";
import CustomSidebar from "../CustomSidebar.tsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../AuthContext.tsx";
registerLocale("it", it);

library.add(faBars, faRightFromBracket, faEllipsisVertical, faCircleArrowLeft);

const date_logo = require("../../../static/img/date-logo.png");

export default function Opening() {
  const { idToken, selectedShop } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [dateOpenings, setDateOpenings] = useState("");

  const [hourStartMorning, setHourStartMorning] = useState("");
  const [minuteStartMorning, setMinuteStartMorning] = useState("");
  const [hourEndMorning, setHourEndMorning] = useState("");
  const [minuteEndMorning, setMinuteEndMorning] = useState("");

  const [hourStartAfternoon, setHourStartAfternoon] = useState("");
  const [minuteStartAfternoon, setMinuteStartAfternoon] = useState("");
  const [hourEndAfternoon, setHourEndAfternoon] = useState("");
  const [minuteEndAfternoon, setMinuteEndAfternoon] = useState("");

  const [openingDb, setOpeningDb] = useState([]);

  const resetForm = () => {
    setSelectedDate("");
    setDateOpenings("");
    setHourStartMorning("");
    setMinuteStartMorning("");
    setHourEndMorning("");
    setMinuteEndMorning("");
    setHourStartAfternoon("");
    setMinuteStartAfternoon("");
    setHourEndAfternoon("");
    setMinuteEndAfternoon("");
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return null;
    }
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]) - 1; // Sottrai 1 perché i mesi sono indicizzati da 0 a 11
    const day = parseInt(dateParts[0]);
    const dateObject = new Date(year, month, day);

    // Array di nomi dei giorni della settimana e dei mesi
    const daysOfWeek = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    // Estrarre il giorno della settimana, il giorno del mese e il mese dalla data
    const dayOfWeek = daysOfWeek[dateObject.getDay()];
    const dayOfMonth = dateObject.getDate();
    const monthName = months[dateObject.getMonth()];

    // Costruire la stringa del risultato
    const formattedDate = `${dayOfWeek} ${dayOfMonth} ${monthName}`;

    return formattedDate;
  };

  const getDaysToOpen = (dateStr: string) => {
    if (!dateStr) {
      return null;
    }
    const dateParts = dateStr.split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[0]);
    const openingDate = new Date(year, month - 1, day).getTime();
    const today = new Date().getTime();
    const diffTime = openingDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleChangeDate = (date) => {
    // Verifica che date sia un oggetto Date valido
    date = date.toLocaleDateString();
    // inverto giorno e mese
    date = date.split("/").reverse().join("/");
    setSelectedDate(date);
    var formattedDate = format(date, "dd/MM/yyyy");
    //@ts-ignore
    formattedDate = formatDate(formattedDate);
    setDateOpenings(formattedDate);
  };

  const handleHourStartMorning = (e) => {
    setHourStartMorning(e.target.value);
  };
  const handleMinuteStartMorning = (e) => {
    setMinuteStartMorning(e.target.value);
  };
  const handleHourEndMorning = (e) => {
    setHourEndMorning(e.target.value);
  };
  const handleMinuteEndMorning = (e) => {
    setMinuteEndMorning(e.target.value);
  };
  const handleHourStartAfternoon = (e) => {
    setHourStartAfternoon(e.target.value);
  };
  const handleMinuteStartAfternoon = (e) => {
    setMinuteStartAfternoon(e.target.value);
  };
  const handleHourEndAfternoon = (e) => {
    setHourEndAfternoon(e.target.value);
  };
  const handleMinuteEndAfternoon = (e) => {
    setMinuteEndAfternoon(e.target.value);
  };

  const getOpeningDb = useCallback(async () => {
    try {
      const response = await axios.post(
        "https://tommasoceredi.it/api/v1/getOpening",
        {
          indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const itDate = response.data.result.map((opening) => {
        // ogni apertura il campo data viene convertito in toLocaleDateString("it-IT")
        const date = new Date(opening.data);
        const formattedDate = date.toLocaleDateString("it-IT");
        return {
          id: formattedDate,
          data: formattedDate,
          oraInizio: opening.oraInizio,
          oraFine: opening.oraFine,
          oraInizioP: opening.oraInizioP,
          oraFineP: opening.oraFineP,
        };
      });
      const sortedData = itDate.sort((a, b) => {
        const dateA = new Date(a.data.replace(/\//g, "-"));
        const dateB = new Date(b.data.replace(/\//g, "-"));
        return dateA.getTime() - dateB.getTime();
      });
      console.log(sortedData);
      setOpeningDb(sortedData);
    } catch (error) {
      console.log(error);
    }
  }, [selectedShop, idToken]);

  const delateOpeningDb = async (id) => {
    // converto la data id in formato YYYY-MM-DD
    id = id.split("/").reverse().join("-");
    console.log(selectedShop);
    axios
      .post(
        "https://tommasoceredi.it/api/v1/deleteOpening",
        {
          Rif_data: id,
          Rif_Indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          getOpeningDb();
          toast.success("Apertura eliminata con successo");
        } else {
          toast.error("Errore nell'eliminazione dell'apertura");
          console.error(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addOpeningDb = async () => {
    const data = {
      day: selectedDate,
      aperturaMattina: hourStartMorning + ":" + minuteStartMorning,
      chiusuraMattina: hourEndMorning + ":" + minuteEndMorning,
      aperturaPomeriggio: hourStartAfternoon + ":" + minuteStartAfternoon,
      chiusuraPomeriggio: hourEndAfternoon + ":" + minuteEndAfternoon,
    };
    if (hourStartMorning === "" || minuteStartMorning === "" || hourEndMorning === "" || minuteEndMorning === "") {
      setHourStartMorning("");
      setMinuteStartMorning("");
      setHourEndMorning("");
      setMinuteEndMorning("");
      data.aperturaMattina = "";
      data.chiusuraMattina = "";
    }
    if (hourStartAfternoon === "" || minuteStartAfternoon === "" || hourEndAfternoon === "" || minuteEndAfternoon === "") {
      setHourStartAfternoon("");
      setMinuteStartAfternoon("");
      setHourEndAfternoon("");
      setMinuteEndAfternoon("");
      data.aperturaPomeriggio = "";
      data.chiusuraPomeriggio = "";
    }
    if (selectedDate === "") {
      toast.error("Seleziona una data");
      return;
    }
    try {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/addOpening",
          {
            data: data.day,
            incasso: 0,
            oraInizio: data.aperturaMattina,
            oraFine: data.chiusuraMattina,
            oraInizioP: data.aperturaPomeriggio,
            oraFineP: data.chiusuraPomeriggio,
            Indirizzo: selectedShop?.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            getOpeningDb();
            toast.success("Apertura aggiunta con successo");
            resetForm();
          } else {
            toast.error("Errore nell'aggiunta dell'apertura");
            console.error(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [isOpen, setIsOpen] = useState(Array(openingDb.length).fill(false));

  const toggleMenu = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getOpeningDb();
    };

    const initialize = async () => {
      await fetchData();
    };

    initialize();
  }, [getOpeningDb]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className="min-h-screen flex flex-col  " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="items-center justify-center">
              <div className="ml-10 mt-10">
                <h1 className="text-2xl font-bold text-white">Aggiungi apertura</h1>
              </div>
              <div className="mt-5 grid grid-cols-1 gap-4 lg:grid-cols-5 lg:item-center lg:justify-center">
                <div className="relative max-w-xs flex flex-col items-center justify-center">
                  <h1 className="absolute top-0 left-0 right-0 text-center font-bold text-white mt-10">Data</h1>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleChangeDate}
                    locale="it"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Seleziona una data"
                    className="w-40 px-3 py-2 rounded border border-gray-300 focus:border-indigo-500 mt-20"
                    minDate={new Date()}
                  />
                </div>

                <div className="relative max-w-xs flex flex-col items-center justify-center">
                  <h1 className="absolute top-0 left-0 right-0 text-center font-bold text-white mt-10">Apertura mattina</h1>
                  <div className="flex items-center mt-20">
                    <select
                      value={hourStartMorning}
                      onChange={handleHourStartMorning}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[8, 9, 10, 11, 12, 13].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span className="px-2 pt-2 pb-2" style={{ backgroundColor: "white" }}>
                      :
                    </span>
                    <select
                      value={minuteStartMorning}
                      onChange={handleMinuteStartMorning}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[0, 15, 30, 45].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="relative max-w-xs flex flex-col items-center justify-center">
                  <h1 className="absolute top-0 left-0 right-0 text-center font-bold text-white mt-10">Chiusura mattina</h1>
                  <div className="flex items-center mt-20">
                    <select
                      value={hourEndMorning}
                      onChange={handleHourEndMorning}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[8, 9, 10, 11, 12, 13].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span className="px-2 pt-2 pb-2" style={{ backgroundColor: "white" }}>
                      :
                    </span>
                    <select
                      value={minuteEndMorning}
                      onChange={handleMinuteEndMorning}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[0, 15, 30, 45].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="relative max-w-xs flex flex-col items-center justify-center">
                  <h1 className="absolute top-0 left-0 right-0 text-center font-bold text-white mt-10">Apertura pomeriggio</h1>
                  <div className="flex items-center mt-20">
                    <select
                      value={hourStartAfternoon}
                      onChange={handleHourStartAfternoon}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[13, 14, 15, 16, 17, 18, 19].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span className="px-2 pt-2 pb-2" style={{ backgroundColor: "white" }}>
                      :
                    </span>
                    <select
                      value={minuteStartAfternoon}
                      onChange={handleMinuteStartAfternoon}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[0, 15, 30, 45].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="relative max-w-xs flex flex-col items-center justify-center">
                  <h1 className="absolute top-0 left-0 right-0 text-center font-bold text-white mt-10">Chiusura pomeriggio</h1>
                  <div className="flex items-center mt-20">
                    <select
                      value={hourEndAfternoon}
                      onChange={handleHourEndAfternoon}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[13, 14, 15, 16, 17, 18, 19, 20].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span className="px-2 pt-2 pb-2" style={{ backgroundColor: "white" }}>
                      :
                    </span>
                    <select
                      value={minuteEndAfternoon}
                      onChange={handleMinuteEndAfternoon}
                      className="w-18 h-10 px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">MM</option>
                      {[0, 15, 30, 45].map((min) => (
                        <option key={min} value={min.toString().padStart(2, "0")}>
                          {min.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <span className="flex justify-center mt-8 text-white">PREVIEW</span>
              <div className="flex justify-center mt-5">
                <div className="w-full max-w-xs bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="mb-3 mt-3 flex justify-center px-2 pt-2">
                    <span className="text-1xl font-bold text-gray-900 dark:text-white">
                      {getDaysToOpen(selectedDate)! > 1 ? (
                        "Apertura tra " + getDaysToOpen(selectedDate) + " giorni"
                      ) : getDaysToOpen(selectedDate) === 1 ? (
                        "Apertura domani"
                      ) : getDaysToOpen(selectedDate) === 0 ? (
                        <div className="flex items-center justify-center w-32 h-8 bg-green-600 rounded-full shadow-lg">
                          Aperto oggi
                        </div>
                      ) : (
                        "Apertura passata"
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col items-center pb-5">
                    <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={date_logo} alt="Date-logo" />
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{dateOpenings}</h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {hourStartMorning && minuteStartMorning && hourEndMorning && minuteEndMorning
                        ? `Mattino: ${hourStartMorning}:${minuteStartMorning} - ${hourEndMorning}:${minuteEndMorning}`
                        : "Mattino: chiuso"}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {hourStartAfternoon && minuteStartAfternoon && hourEndAfternoon && minuteEndAfternoon
                        ? `Pomeriggio: ${hourStartAfternoon}:${minuteStartAfternoon} - ${hourEndAfternoon}:${minuteEndAfternoon}`
                        : "Pomeriggio: chiuso"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={addOpeningDb}
                >
                  Aggiungi
                </button>
              </div>
              <div className="ml-10 mt-10 mb-10">
                <h1 className="text-2xl font-bold text-white">Prossime aperture aggiunte</h1>
              </div>

              <div className="flex flex-wrap justify-center gap-6 mb-20">
                {openingDb.map((opening, index) => (
                  <div
                    //@ts-ignore
                    key={opening.id}
                    className="w-full max-w-xs bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                  >
                    <div className="flex justify-end px-2 pt-2">
                      <div className="relative inline-block">
                        <button
                          onClick={() => toggleMenu(index)}
                          id={`dropdownButton-${index}`} // Aggiungi un ID univoco per ciascun pulsante
                          className="inline-flex items-center justify-center w-10 h-10 text-gray-600 rounded-full focus:outline-none focus:bg-gray-700"
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        {isOpen[index] && (
                          <div className="dropdown z-10 absolute right-0 mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
                            <ul className="py-2" aria-labelledby={`dropdownButton-${index}`}>
                              <li>
                                <div
                                  onClick={async () => {
                                    //@ts-ignore
                                    await delateOpeningDb(opening.id);
                                    toggleMenu(index);
                                  }}
                                  className="block px-4 py-2 text-sm text-red-600 cursor-pointer"
                                >
                                  Delete
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 mt-3 flex justify-center px-2 pt-2">
                      <span className="text-1xl font-bold text-gray-900 dark:text-white">
                        {
                          //@ts-ignore
                          getDaysToOpen(opening.data) > 1 ? (
                            "Apertura tra " +
                            //@ts-ignore
                            getDaysToOpen(opening.data) +
                            " giorni"
                          ) : //@ts-ignore
                          getDaysToOpen(opening.data) === 1 ? (
                            "Apertura domani"
                          ) : //@ts-ignore
                          getDaysToOpen(opening.data) === 0 ? (
                            <div className="flex items-center justify-center w-32 h-8 bg-green-600 rounded-full shadow-lg">
                              Aperto oggi
                            </div>
                          ) : (
                            "Apertura passata"
                          )
                        }
                      </span>
                    </div>
                    <div className="flex flex-col items-center pb-5">
                      <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={date_logo} alt="Date-logo" />
                      <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                        {formatDate(
                          //@ts-ignore
                          opening.data
                        )}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {
                          //@ts-ignore
                          opening.oraInizio !== "" ||
                          //@ts-ignore
                          opening.oraFine !== ""
                            ? "Mattina: " +
                              //@ts-ignore
                              opening.oraInizio +
                              " - " +
                              //@ts-ignore
                              opening.oraFine
                            : "Mattina: chiuso"
                        }
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {
                          //@ts-ignore
                          opening.oraInizioP !== "" ||
                          //@ts-ignore
                          opening.oraFineP !== ""
                            ? "Pomeriggio: " +
                              //@ts-ignore
                              opening.oraInizioP +
                              " - " +
                              //@ts-ignore
                              opening.oraFineP
                            : "Pomeriggio: chiuso"
                        }
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
