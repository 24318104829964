import React, { useEffect, useState, useCallback } from "react";
import CustomSidebar from "../CustomSidebar.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCircleXmark, faCircleCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../NavAdmin.tsx";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../AuthContext.tsx";
import axios from "axios";

library.add(faBars, faCircleXmark, faCircleCheck, faEdit);

export default function Dashboard() {
  const { idToken, selectedShop } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [addRefundPopup, setAddRefundPopup] = useState<boolean>(false);
  const [refundPopup, setRefundPopup] = useState<any>(false);

  const [betterRimborsi, setBetterRimborsi] = useState<any[]>([]); // Array di array di oggetti
  const [anno, setAnno] = useState<number[]>([]);
  const [employee, setEmployee] = useState<any[]>([]);
  const [formRefund, setFormRefund] = useState({
    data: "",
    descrizione: "",
    importo: 0,
    persona: "",
    rimborsato: false,
  });

  const handleDeleteRefund = useCallback(
    async (id) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/deleteRefund",
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Rimborso cancellato con successo");
            setRefundPopup(false);
            getRimborsiAnno(new Date().getFullYear());
          } else {
            toast.error("Errore nella cancellazione del rimborso");
            console.error("Error:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Errore nella cancellazione del rimborso");
        });
    },
    [idToken, selectedShop]
  );

  const handleUpdateRefund = useCallback(
    async (rimborso: any) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/updateRefund",
          {
            id: rimborso.id,
            rimborsato: !rimborso.rimborsato,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            toast.success("Rimborso aggiornato con successo");
            setRefundPopup(false);
            getRimborsiAnno(new Date(rimborso.data).getFullYear());
          } else {
            toast.error("Errore nell'aggiornamento del rimborso");
            console.error("Error:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Errore nell'aggiornamento del rimborso");
        });
    },
    [idToken, selectedShop]
  );

  const getRimborsiAnno = useCallback(
    async (anno: number) => {
      axios
        .post(
          "https://tommasoceredi.it/api/v1/getRefund",
          {
            anno: anno,
            Indirizzo: selectedShop?.Indirizzo,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            // ordina dal più recente al più vecchio secondo "data"
            const sortedData = response.data.result.sort((a: any, b: any) => {
              return new Date(b.data).getTime() - new Date(a.data).getTime();
            });
            setBetterRimborsi((prev) => ({ ...prev, [anno]: sortedData }));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Errore nel caricamento dei rimborsi");
        });
    },
    [idToken, selectedShop]
  );

  const getEmployee = useCallback(async () => {
    axios
      .get("https://tommasoceredi.it/api/v1/getEmployee", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          const formattedEmployee = response.data.result.map((employee) => ({
            id: employee.CF,
            Nome: employee.Nome,
            Cognome: employee.Cognome,
            CF: employee.CF,
          }));
          setEmployee(formattedEmployee);
        }
      })
      .catch((error) => {
        console.error("Errore nel recupero dei commessi:", error);
      });
  }, [idToken]);

  const handleAddRefund = useCallback(async () => {
    axios
      .post(
        "https://tommasoceredi.it/api/v1/addRefund",
        {
          data: formRefund.data,
          descrizione: formRefund.descrizione,
          importo: formRefund.importo,
          persona: formRefund.persona,
          rimborsato: formRefund.rimborsato,
          Indirizzo: selectedShop?.Indirizzo,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Rimborso aggiunto con successo");
          setAddRefundPopup(false);
          getRimborsiAnno(new Date(formRefund.data).getFullYear());
        } else {
          toast.error("Errore nell'aggiunta del rimborso");
          console.error("Error:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Errore nell'aggiunta del rimborso");
      });
  }, [formRefund, idToken, selectedShop, getRimborsiAnno]);

  const betterDate = (date: string) => {
    // Presa la stringa gg/mm/aaaa, la converte in Sabato 1 Gennaio 2022
    const [day, month, year] = date.split("/");
    const newDate = new Date(Number(year), Number(month) - 1, Number(day));
    return newDate.toLocaleDateString("it-IT", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    getRimborsiAnno(new Date().getFullYear());
    getEmployee();
  }, [getRimborsiAnno, getEmployee]);

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            style: {
              background: "#424242",
              color: "#fff",
            },
          },
          custom: {
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          },
        }}
      />
      <div className="flex">
        <CustomSidebar toggled={toggled} setToggled={setToggled} setBroken={setBroken} />
        <main className="flex-grow">
          <div className="min-h-screen flex flex-col  " style={{ backgroundColor: "#141b2d" }}>
            <NavAdmin broken={broken} toggled={toggled} setToggled={setToggled} />
            <div className="max-w-screen-xl w-full mx-auto p-4">
              <caption className="m-5 p-5 flex items-center rounded-lg justify-between text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                <div>
                  Spese sostenute
                  <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                    Visualizza e gestisci i rimborsi sostenuti dal negozio
                  </p>
                </div>
                <button
                  className="px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                  onClick={() => setAddRefundPopup(true)}
                >
                  Aggiungi Spesa
                </button>
              </caption>
              {Object.keys(betterRimborsi)
                .sort((a, b) => Number(b) - Number(a)) // Ordina gli anni in ordine decrescente
                .map((anno) => (
                  <div key={anno}>
                    <h2 className="text-white text-2xl font-bold p-4">{anno}</h2>
                    <table className="min-w-full bg-white mb-8">
                      <thead className="bg-gray-800 text-white">
                        <tr>
                          <th className="py-2 px-4">Data</th>
                          <th className="py-2 px-4">Descrizione</th>
                          <th className="py-2 px-4">Importo</th>
                          <th className="py-2 px-4">Persona</th>
                          <th className="py-2 px-4">Rimborsato</th>
                          <th className="py-2 px-4"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {betterRimborsi[anno].map((rimborso) => (
                          <tr key={rimborso.id}>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              {betterDate(new Date(rimborso.data).toLocaleDateString())}
                            </td>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              {rimborso.descrizione}
                            </td>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              {rimborso.importo}€
                            </td>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              {employee.find((emp) => emp.id === rimborso.persona)?.Nome}{" "}
                              {employee.find((emp) => emp.id === rimborso.persona)?.Cognome}
                            </td>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              {rimborso.rimborsato ? (
                                <FontAwesomeIcon icon={faCircleCheck} className=" text-green-500" />
                              ) : (
                                <FontAwesomeIcon icon={faCircleXmark} className=" text-red-500" />
                              )}
                            </td>
                            <td className="text-center align-middle py-3 px-6 text-left whitespace-nowrap">
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="cursor-pointer"
                                onClick={() => setRefundPopup(rimborso)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
            {addRefundPopup ? (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-8 rounded-lg">
                  <h2 className="text-2xl font-bold mb-4">Aggiungi rimborso</h2>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddRefund();
                    }}
                  >
                    <div className="mb-4">
                      <label htmlFor="data" className="block text-sm font-medium text-gray-700">
                        Data
                      </label>
                      <input
                        type="date"
                        id="data"
                        name="data"
                        value={formRefund.data}
                        onChange={(e) => setFormRefund({ ...formRefund, data: e.target.value })}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                                "
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="descrizione" className="block text-sm font-medium text-gray-700">
                        Descrizione
                      </label>
                      <input
                        type="text"
                        id="descrizione"
                        name="descrizione"
                        list="descrizione-suggestions"
                        value={formRefund.descrizione}
                        onChange={(e) => setFormRefund({ ...formRefund, descrizione: e.target.value })}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <datalist id="descrizione-suggestions">
                        <option value="Autostrada" />
                        <option value="Carburante" />
                        <option value="Pranzo" />
                        <option value="Pubblicità" />
                        <option value="Treno" />
                      </datalist>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="importo" className="block text-sm font-medium text-gray-700">
                        Importo
                      </label>
                      <input
                        type="number"
                        id="importo"
                        name="importo"
                        value={formRefund.importo}
                        onChange={(e) => setFormRefund({ ...formRefund, importo: Number(e.target.value) })}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                                "
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="persona" className="block text-sm font-medium text-gray-700">
                        Persona
                      </label>
                      <select
                        id="persona"
                        name="persona"
                        value={formRefund.persona}
                        onChange={(e) => setFormRefund({ ...formRefund, persona: e.target.value })}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Seleziona un impiegato</option>
                        {employee.map((emp) => (
                          <option key={emp.CF} value={emp.CF}>
                            {emp.Nome} {emp.Cognome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="blisterato" className="block text-sm font-medium text-gray-700">
                        Rimborsato
                      </label>
                      <div
                        className={`relative inline-flex items-center cursor-pointer`}
                        onClick={() => setFormRefund((prev) => ({ ...prev, rimborsato: !prev.rimborsato }))}
                      >
                        <div
                          className={`block w-10 h-6 rounded-full transition-colors ${
                            formRefund.rimborsato ? "bg-green-600" : "bg-gray-300 dark:bg-gray-600"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition ${
                            formRefund.rimborsato ? "translate-x-4" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                      Aggiungi
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded-md ml-2" onClick={() => setAddRefundPopup(false)}>
                      Annulla
                    </button>
                  </form>
                </div>
              </div>
            ) : null}

            {refundPopup && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm animate-fadeIn">
                <div className="bg-white p-6 rounded-2xl shadow-lg w-96">
                  <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">Modifica rimborso</h2>
                  <div className="flex flex-col gap-4">
                    <p className="text-sm font-semibold text-gray-800">
                      Data: {betterDate(new Date(refundPopup.data).toLocaleDateString())}
                    </p>
                    <p className="text-sm font-semibold text-gray-800">
                      Descrizione: {refundPopup.descrizione} - {refundPopup.importo}€
                    </p>
                    <button
                      className="w-full bg-red-500 hover:bg-red-600 text-white py-2 rounded-lg transition"
                      onClick={() => handleDeleteRefund(refundPopup.id)}
                    >
                      Cancella
                    </button>
                    <button
                      className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded-lg transition"
                      onClick={() => handleUpdateRefund(refundPopup)}
                    >
                      {refundPopup.rimborsato ? "Segna come non rimborsato" : "Segna come rimborsato"}
                    </button>
                    <button
                      className="w-full bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 rounded-lg transition"
                      onClick={() => setRefundPopup(false)}
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
